import React, { useState } from 'react';
import './changePassword.css'
import axios from 'axios';
import './profile.css';
import Toptag from '../../components/toptag/Toptag';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material/';
import { Alert, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import apiFunctions from '../../api/apiFunctions';




const BackPaper = styled(Paper)({
    padding: "20px",
    height: "540px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


export default function ChangePassword() {

    const [inputPass1, setInputPass1] = useState('password');
    const [inputPass2, setInputPass2] = useState('password');
    const navigate = useNavigate();
    const [err,setError] = useState(false)


    const [body, setBody] = useState({ old_password: '', new_password: '', confirm_password: '' });
    const isDisabled = body.confirm_password === '';

    const handleKeyDown = e => {
        console.log(e.target.value)
        if (e.target.value === '') {
            if (e.key === " ") {
                e.preventDefault();
            }
        }
    };

    const inputEvent = (e) => {
        const { id, value } = e.target;
        setBody((prevalue) => {
            return {
                ...prevalue,
                [id]: value
            };
        });
    };

    const submitChangePassword = async (event) => {
        event.preventDefault();
        try {
            if(body.old_password===""||body.new_password===""||body.confirm_password===""){
                setError(true)
                toast.error("All fields are required!")
            }else{
                const result = await apiFunctions.changePassword(body);
                if (!result.data.status) {
                    if (result.data.code === 401) {
                    localStorage.clear();
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.success('Password changed successfully!');
                setBody({ old_password: '', new_password: '', confirm_password: '' });
                setError(false)
            }
        }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };


    const changeInput1 = () => { if (inputPass1 === 'password') { setInputPass1('text') } else { setInputPass1('password') } }
    const changeInput2 = () => { if (inputPass2 === 'password') { setInputPass2('text') } else { setInputPass2('password') } }


    return (
        <>
            <Toptag />
            <ToastContainer autoClose={1000} />
            <BackPaper>
        
                <Grid container spacing={3} style={{ marginTop: "30px" }}>
                    <Grid xs={12} sm={12} md={6} lg={6} item>
                        <Grid container spacing={6} direction="column" justifyContent="space-evenly" alignItems="center">
                            <Grid item><label className='labelCont'>Old Password</label></Grid>
                            <Grid item><label className='labelCont'>New Password</label></Grid>
                            <Grid item><label className='labelCont'>Confirm New Password</label></Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={6} item>
                        <Grid container spacing={3} direction="column" justifyContent="space-evenly" alignItems="center">
                            <Grid style={{ width: "100%" }} item>
                                <input type="password" name="old_password" value={body.old_password} id="old_password" className="searchBarInput" onChange={inputEvent} />
                                {err && body.old_password === '' ? <div className="errText">Old Password is required!</div> : null}
                            </Grid>
                            <Grid style={{ width: "100%" }} item>
                                <input type="password" name="new_password" value={body.new_password} id="new_password" className="searchBarInput" onChange={inputEvent} />
                                {err && body.new_password === '' ? <div className="errText">New Password is required!</div> : null}
                            </Grid>
                            <Grid style={{ width: "100%" }} item>
                                <input type="password" name="confirm_password" value={body.confirm_password} id="confirm_password" className="searchBarInput" onChange={inputEvent} />
                                {err && body.confirm_password === '' ? <div className="errText">Confirm Password is required!</div> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="row" style={{ marginTop: "50px" }} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Tooltip title="Click to Update Password" >
                            <button className='newBtn' onClick={submitChangePassword}
                            >Update Password</button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BackPaper>

        </>
    )
}
