import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiFunctions from '../../api/apiFunctions';
import { useNavigate } from 'react-router-dom';
import "./login.css";


export default function Login() {

    const navigate = useNavigate();
    const [style, setStyle] = useState("container1 left-panel-active");
    const [login, setLogin] = useState({
        email: "",
        password: ""
    })
    const [message, setMessage] = useState(false);

    const changeStyle1 = () => {
        setStyle("container1 right-panel-active");
    };
    const changeStyle2 = () => {
        setStyle("container1 left-panel-active");
    };

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault()
        }
    }

    const inputEvent = (e) => {
        const { id, value } = e.target
        setLogin((previous) => {
            return {
                ...previous,
                [id]: value
            }
        })
    }

// console.log(login, "login-----------")
    const loginFormSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        let loginDataObj = {
            ...login
        }
        // console.log(loginDataObj, "logindataObject------------")
        try {
            const res = await apiFunctions.login(loginDataObj);
            // console.log(res, "res------------------")
            if (!res.data.status) {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                localStorage.setItem('token', res.data.data.token.auth);
                localStorage.setItem('profilePhoto', res.data.data.profilePhoto)
                navigate('/')
                toast.success('Logged in !', {
                    position: toast.POSITION.TOP_RIGHT
                })
                setTimeout(window.location.reload.bind(window.location), 10);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const submitFormForgotPass = async (e) => {
        e.preventDefault();
        let forgetPassDataObj = {
            email: login.email
        }

        try {
            const res = await apiFunctions.forgotPassword(forgetPassDataObj)
            console.log(res, "res for forgetpoosasswoieijdhe----------------------")
            if (!res.data.status) {
                console.error('Error:', res.data.message);
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            setMessage(true)
        } catch (error) {
            console.error('Error:', error);
            window.alert(error.message)
        }
    }

    return (
        <>
        <div >
            <ToastContainer autoClose={1000} />
            <div >
            <div className='fill_center' >
                <div className={style} id="container">
                    <div className="form-container sign-up-container">
                        <form onSubmit={submitFormForgotPass}>
                            {message === true ? (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="message">Email Verified Successfully!</div>
                                        <div className="paraMessage">
                                            Please check your email we send you new password!
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                <div className="LoginLogo">Climate&nbsp;Meet</div>
                                    <h1 className="heading2">Recover Account</h1>
                                    <input
                                        type="email"
                                        id="email"
                                        onKeyDown={handleKeyDown}
                                        onChange={inputEvent}
                                        required={true}
                                        placeholder="Email"
                                    />
                                    <button className="singinButton" style={{ marginTop: "17px" }}>
                                        Submit
                                    </button>
                                </>
                            )}
                        </form>
                    </div>

                    <div className="form-container sign-in-container">
                        <form onSubmit={loginFormSubmit}>
                        <div className="LoginLogo">Climate&nbsp;Meet</div>
                            <h1 className='heading1'>SIGN IN</h1>
                            <input type="email" id='email' onKeyDown={handleKeyDown} onChange={inputEvent} required={true} placeholder="Email" />
                            <input type="password" id='password' onKeyDown={handleKeyDown} onChange={inputEvent} required={true} placeholder="Password" />
                            <button className='singinButton' >Sign In</button>
                        </form>
                    </div>

                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Hello, Admin!</h1>
                                <p>Login with your credentials to access the admin panel</p>
                                <button className="ghost" id="signIn" onClick={changeStyle2}>Sign In</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Welcome Back!</h1>
                                <p>To recover your account password, Click below!</p>
                                <button className="ghost" id="signUp" onClick={changeStyle1}>Forgot Password?</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
</div>
        </>
    )
};