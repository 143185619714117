import React, { useEffect, useState } from 'react'
import { PermIdentity, Timeline, Person4 } from "@mui/icons-material"
import { NavLink } from 'react-router-dom';
import Toptag from "../toptag/Toptag"
import "./card.css"
import apiFunctions from '../../api/apiFunctions';




export default function Card() {
    const [limit, setLimit] = useState('1000');
    const [searchKey, setSearchKey] = useState('');
    const [page, setPage] = useState("1")
    const [usersCount, setUsersCount] = useState({ users: 0})
    const [eventCount, setEventCount] = useState({ events: 0})

    let formatter = Intl.NumberFormat('en', { notation: 'compact' });


    async function fetchUsersCount() {
        const users = await apiFunctions.getAllUsers(limit, searchKey, page);
        console.log(users, "users,++++++++++++++++++++++++")
        if (!users) {
            setUsersCount({ users: 0 })
        }
        setUsersCount({users: users.data.total_count})
    }

    async function fetchEventCount() {
        const events = await apiFunctions.getAllEvents(limit, searchKey, page);
        console.log(events, "usersevents,++++++++++++++++++++++++")
        if (!events) {
            setEventCount({events: 0 })
        }
        setEventCount({events: events.data.total_count})
    }

    useEffect(() => {
        // eslint-disable-next-line
       
        //  console.log(data,"useStatae data-----------")
        fetchUsersCount()
        fetchEventCount()
    }, [])


    return (
        <>
        <Toptag />
            <div className="container">
                <div className="cardWrapper">
                    <NavLink to='/home/users' className='link'>
                        <div className="cardContainer">
                            <div className="cardTopLogo ">
                                <Person4 className='cardLogo' />
                            </div>
                            <span className='cardTitle green'>Users</span>
                            <span className='userCount'><Timeline className='trendingLogo' />{formatter.format(usersCount.users)}</span>
                        </div>
                    </NavLink>
                    <NavLink to='/home/events' className='link'>
                        <div className="cardContainer">
                            <div className="cardTopLogo ">
                                <Person4 className='cardLogo' />
                            </div>
                            <span className='cardTitle green'>Events</span>
                            <span className='userCount'><Timeline className='trendingLogo' />{formatter.format(eventCount.events)}</span>
                        </div>
                    </NavLink>
                </div>
            </div>
        </>
    )
}
