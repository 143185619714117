import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, UploadFile, WorkspacePremium } from '@mui/icons-material'
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, useMediaQuery, useTheme, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import "./faq.css"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
import apiFunctions from '../../api/apiFunctions'



const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const Faq = () => {
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [usersCount, setUsersCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(5);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [id, setId] = useState()
    const [create, setCreate] = useState(false)
    const [err, setErr] = useState(false)
    const [obj, setObj] = useState({
        question: '', answer: ''
    })


    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }

    const handleUserOpen = () => {
        setCreate(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let userObj = {
            question: obj.question,
            answer: obj.answer,
        }

        const result = await apiFunctions.createFaq(userObj);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setCreate(false)
            getAll()
            // setTimeout(window.location.reload.bind(window.location), 10);
            toast.success("Faq created succesfully!")
        }

    };

    const addUserData = (e) => {
        const { name, value } = e.target;
            setObj({
                ...obj,
                [name]: value,
            });
    }

    const getAll = async () => {
        let result = await apiFunctions.getAllFaq(limit, searchKey, page);
        console.log(result, "result-------------------999999999")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data);
            setUsersCount(result.data.total_count)
            setIsloading(false)
        }
    }

    useEffect(() => {
        getAll()
    }, [searchKey, limit, page])

    const removefaq = async (id) => {
        const result = await apiFunctions.removeFaq(id);
        console.log(result, "result-------------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAll();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const handleChange = (e) => {
        if (e.target.value === usersCount) {
            setSkip(0)
            setLimit(usersCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }

    function truncateStringToWords(str, numWords) {
        const words = str.split('');
        const truncatedWords = words.slice(0, numWords);
        return truncatedWords.join('');
    }

    return (
        <>
            <div className='container'>
                {isLoading ? (
                    <Loader />) : (
                    <>
                        <Toptag />
                        <ToastContainer autoClose={1000} />
                        <NavPaper>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div></div>
                                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total faqs - {usersCount}</div></Grid>
                                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page - </div></Grid>
                                    <Grid item>
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard-label"
                                                value={limit}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="5">5</MenuItem>
                                                <MenuItem value="10">10</MenuItem>
                                                <MenuItem value="20">20</MenuItem>
                                                <MenuItem value="30">30</MenuItem>
                                                <MenuItem value={usersCount}>All</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <div className='searchBar'>
                                            <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                            <Search className='searchIcon' />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <button className='newBtn' onClick={handleUserOpen}>Add Faq</button>
                                    </Grid>
                                </Grid>

                            </div>
                        </NavPaper>
                        <BackPaper>
                            {all.length === 0 && searchKey ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/nodata.jpg" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                    <Grid item><div className="errorMessage">User not found!</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell style={{width: "850px"}}>Question</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {all.map((element, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <Accordion style={{ width: "800px" }}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={`panel${index + 1}a-content`}
                                                                id={`panel${index + 1}a-header`}
                                                            >
                                                            {element.question}
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ width: "800px" }}>
                                                                {truncateStringToWords(element.answer, 200)}...
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={1} direction="row" justifyContent="start" alignItems="start">
                                                            <Grid item>
                                                                <Tooltip title="view Faq Detail" >
                                                                    <NavLink to='/home/faq/detail' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Delete User" >
                                                                    <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            }


                            {/* dilogue for delete user */}
                            <Dialog open={openDialogForRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">
                                    {"Are you sure want to remove this Faq?"}
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleRemoveClose}>No</Button>
                                    <Button onClick={() => { removefaq(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>

                            {/* dilogue for create user */}
                            <Dialog open={create} aria-labelledby="responsive-dialog-title" >
                                <div className="popUpCreate">
                                    <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                                        <Cancel style={{ fontSize: "25px", color: "black", cursor: "pointer" }} onClick={() => setCreate(false)} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div className='popHeading'>Create New Faq</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                                <div className="popText">Question</div>
                                                <div><input type="text" className="popInput" name="question" id="question" onChange={addUserData} /></div>
                                                {err && obj.name === '' ? <div className="errText">Question is required!</div> : null}
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                                <div className="popText">Answer</div>
                                                <div>
                                                    <textarea type="text" className="popInput" name="answer" id="answer" onChange={addUserData} style={{ height: "150px" }} /></div>
                                                {err && obj.countryCode === '' ? <div className="errText">Answer is Required!</div> : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                                        <div>
                                            <button className='popBtn' onClick={handleSubmit}>Create</button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>

                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                                <div>
                                    <Pagination count={Math.ceil(usersCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                                </div>
                            </div>
                        </BackPaper>

                    </>
                )}
            </div>
        </>

    )
}

export default Faq









