import { AddToPhotos } from '@mui/icons-material'
import { Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import apiFunctions from '../../api/apiFunctions';
import Toptag from '../../components/toptag/Toptag'
import Loader from '../../components/Loader/Loader'


const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


function NewsDetail() {

    const location = useLocation();
    // console.log(location, "location---------------------")
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [image, setImage] = useState("")
    const [image2, setImage2] = useState("")
    const [error, setError] = useState(false)
    const [obj, setObj] = useState({})
    // const [updatedObject, setUpdatedObject] = useState({
    //     user_name: '',
    //     email: '',
    //     skills: '',
    // })
    // console.log(updatedObject, "name at a time when gettin userprofilew----------------")

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // const openDialog = async () => {
    //     setIsDialogOpen(true);
    // };

    const imageChange = (e) => {

        setImage2(e.target.files[0])
        const file = e.target.files[0];
        console.log(file, "file**********************")
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
        } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
            setImage2("")
            setError(true)
        } else {
            setError(true)
        }

    }
    // console.log(image2, "imatge2222222222222222222222222222222")
    // console.log(image, "imageggggggggggggggggggggggg")


    const handlecChange = () => {
        setEdit(true)
    }

    const addata = (e) => {
        console.log(e.target.value, "value----------+++++++++++++++")
        const { name, value } = e.target;
        setObj((obj) => ({
            ...obj,
            [name]: value,
        }));
    }

    const getNewsDetail = async (previous) => {
        const result = await apiFunctions.getNews(location.state.id);
        console.log("hellogetUserDeatil-----------", result)
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setObj(result.data.data)
            setImage(result.data.data.news_photo)
            await delay(1000);
            setIsLoading(false)

        }
    }


    const updateUserProfile = async (event) => {
        const id = location.state.id;
        event.preventDefault();
        localStorage.removeItem('image')
        const formData = new FormData()
        formData.append("image", image2)
        let body;
        console.log(formData, "formData9999999999999")
        const profilePhotoPath = await apiFunctions.uploadNewsPhoto(formData)
        console.log(profilePhotoPath, "profilePhotoPath-------")
        if (!profilePhotoPath.data.status) {
            body = {
                title: obj.title,
                description: obj.description,
                news_photo: image
            }
        } else {
            body = {
                title: obj.title,
                description: obj.description,
                news_photo: profilePhotoPath.data.data.profile_photo
            }
        }
        // console.log(body, "hdhsfhdghfgdshgfgdsgfhdghgfdghgfhdg")
        const result = await apiFunctions.updateNews(id, body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        } else {
            setIsLoading(false)
            setEdit(false)
            setObj(result.data.data)
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
    }


    useEffect(() => {
        getNewsDetail()
    }, [])
    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <BackPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>News Details</Grid>
                            <Grid item>
                                {!edit ? (
                                    <>
                                        <button className='newBtn' onClick={handlecChange}>Edit/Update</button>
                                        {/* <button className='newBtn' onClick={openDialog}>Add friends</button> */}
                                    </>) :
                                    null
                                }
                            </Grid>
                        </Grid>
                        <hr></hr>

                        <Grid container spacing={2}>
                            <Grid item>

                                {image !== '' ? (
                                    <>
                                        <img src={image} alt="photo" style={{height: "200px", width: "925px", marginLeft : "15px"}}/>
                                    </>
                                ) : (
                                    <img src="/images/blank_pic.png" alt="" />

                                )}
                                <Grid item>
                                    {edit ? <div className="image-upload uploadicon">
                                        <label htmlFor="image">
                                            <Tooltip title="Change user profile pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                                        </label>

                                        <input id="image" onChange={imageChange} type="file" name='image' accept="image/*" />
                                    </div> : null}
                                </Grid>
                                {error ? <><Grid item className='fileErr'>Invalid file format. Only JPG, PNG, and other image formats are accepted</Grid>
                                    <Grid item><button className='newBtn' onClick={() => setError(false)}>Ok</button></Grid></> : null}
                            </Grid>
                            
                            <Grid item style={{marginLeft: "15px"}}>Heading</Grid>
                            <Grid item style={{ width: "95%", marginLeft: "15px"}}>
                                <input type="text" disabled={!edit ? true : false} value={obj.title}
                                    name="title" onChange={addata} className="detailBarInput" />
                            </Grid>

                            <Grid item style={{marginLeft: "15px"}}>Description</Grid>
                            <Grid item style={{ width: "95%", marginLeft: "15px" }}>
                                <textarea type="text" disabled={!edit ? true : false} value={obj.description}
                                    name="description" onChange={addata} className="detailBarInput" style={{height : "180px"}}/>
                            </Grid>
                        </Grid>
                        <Grid item>
                                {edit ? <button className='newBtn' onClick={updateUserProfile}>Save</button> : null}
                            </Grid>

                    </BackPaper>
                </>)}
        </>
    )
}

export default NewsDetail