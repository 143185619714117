// const URL = 'http://51.20.167.145:3005/'
// const URL = 'http://localhost:3005/'
// const URL = 'http://208.68.37.50:3008/'
const URL = 'https://api.climatemeet.me/'



const API = {
    logIn: URL + 'api/v1/admin/login',
    forgotPassword: URL + 'api/v1/admin/forgot-password',
    logOut: URL + "api/v1/admin/logout",
    getAdminProfile: URL + 'api/v1/admin/getAdmin/profile',
    getGraphDetails: URL + 'api/v1/admin',
    getTerms: URL + "api/v1/content/slug/admin?slug=terms",
    getSubstantAction: URL + "api/v1/content/slug/admin?slug=substantiated",
    updateTerms: URL + 'api/v1/content',
    getPrivacy: URL + 'api/v1/content/slug/admin?slug=privacy',
    updatePrivacy: URL + 'api/v1/content',
    getLegal: URL + 'api/v1/content/slug/admin?slug=legal',
    updateAdminProfile: URL + 'api/v1/admin',
    changePassword: URL + 'api/v1/admin/change-password',
    uploadPhoto: URL + 'api/v1/users/upload-profile-image',
    uploadGraphPhoto: URL + 'api/v1/graphs/upload',
    uploadNewsPhoto: URL + 'api/v1/news/news-photo',
    createEvent: URL + 'api/v1/events/',
    createSlot: URL + 'api/v1/slots/',
    createNews: URL + 'api/v1/news/',
    createFaq: URL + 'api/v1/faq/',
    addMoreSchedule: URL + 'api/v1/eventsDate/',
    getAllUsers: URL + 'api/v1/users',
    getAllFeedback: URL + 'api/v1/feedbacks',
    getAllNews: URL + 'api/v1/news/admin/new-list',
    getAllFaq: URL + 'api/v1/faq/list/admin',
    getAllEvents: URL + 'api/v1/admin/events/list',
    getAllEventsDate: URL + 'api/v1/eventsDate',
    getAllSuggestions: URL + 'api/v1/suggestions',
    getUserProfile: URL + 'api/v1/users/get/profile',
    getNews: URL + 'api/v1/news',
    getFaq: URL + 'api/v1/faq',
    getFeedback: URL + 'api/v1/feedbacks',
    eventDetail: URL + 'api/v1/events',
    getEventsDateDetail: URL + 'api/v1/eventsDate',
    addFriends: URL + 'api/v1/admin/add/friends/list',
    updateUserProfile: URL + 'api/v1/users',
    updateGraphDetails: URL + 'api/v1/graphs',
    updateNews: URL + 'api/v1/news',
    updateFaq: URL + 'api/v1/faq',
    updateDistance: URL + 'api/v1/admin/update/distance',
    updateEvent: URL + 'api/v1/events',
    updateEventsDate: URL + 'api/v1/eventsDate',
    updateSlots: URL + 'api/v1/slots',
    blockUser: URL + 'api/v1/admin/update/status',
    removeUser: URL + 'api/v1/admin/delete/user',
    removeEvent: URL + 'api/v1/events',
    removeSuggestion: URL + 'api/v1/suggestions',
    removeNews: URL + 'api/v1/news',
    removeFaq: URL + 'api/v1/faq',
    removeFeedback: URL + 'api/v1/feedbacks',
    deleteSlot: URL + 'api/v1/slots',
    deleteDate: URL + 'api/v1/eventsDate',
    getAllRemovedUsers: URL + 'api/v1/admin/deleted/users',
    unFriend : URL + 'api/v1/admin/unfriend/user/friend/list',
    Groups : URL + 'api/v1/groups/admin',
    getAllFriendsAndGroups : URL + 'api/v1/admin/users/friends/list',
    getGroupDetail : URL + 'api/v1/groups/admin/group-detail',
    getSuggestionDetail : URL + 'api/v1/suggestions'
}

export default API;

export { URL }