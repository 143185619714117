import { AddToPhotos } from '@mui/icons-material'
import {  Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate,} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import apiFunctions from '../../api/apiFunctions';
import Toptag from '../../components/toptag/Toptag'
import Loader from '../../components/Loader/Loader'
// import "./userProfile.css"


const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const SuggestionDetail = ({ objs }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [image, setImage] = useState("")
    const [image2, setImage2] = useState("")
    const [error, setError] = useState(false)
    const [searchKey, setSearchKey] = useState("");
    const [obj, setObj] = useState({})


    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


    const imageChange = (e) => {

        setImage2(e.target.files[0])
        const file = e.target.files[0];
        console.log(file, "file**********************")
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
        } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
            setImage2("")
            setError(true)
        } else {
            setError(true)
        }

    }
    // console.log(image2, "imatge2222222222222222222222222222222")
    // console.log(image, "imageggggggggggggggggggggggg")


    const handlecChange = () => {
        setEdit(true)
    }

    const addata = (e) => {
        // console.log(e.target.value, "value----------+++++++++++++++")
        const { name, value } = e.target;
        setObj((obj)=>({
            ...obj,
            [name]: value,
        }));
    }

    // console.log(updatedObject, "updated+++++++++++++++++++++++++++++++++++-----------------------------")


    const getDetail = async (previous) => {
        // console.log("hellogetUserDeatil")
        const result = await apiFunctions.getSuggestionDetail(location.state.id);
        console.log(result, "resu;t****************************")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setObj(result.data.data)
            // setImage(result.data.data.profilePhoto)
            await delay(1000);
            setIsLoading(false)

        }
    }
    // console.log(obj, "objetttttttttttttttttttttttttttttttttttttttttttt+++++++++++")

    const updateUserProfile = async (event) => {
        const id = location.state.id;
        event.preventDefault();
        localStorage.removeItem('image')
        const formData = new FormData()
        formData.append("image", image2)
        let body;
        console.log(formData, "formData9999999999999")
        const profilePhotoPath = await apiFunctions.uploadPhoto(formData)
        // console.log(profilePhotoPath, "profilePhotoPath-------")
        if (!profilePhotoPath.data.status) {
            body = {
                user_name: obj.user_name,
                email: obj.email,
                skills: obj.skills,
                profilePhoto: image
            }
        } else {
            body = {
                user_name: obj.user_name,
                email: obj.email,
                skills: obj.skills,
                profilePhoto: profilePhotoPath.data.data.profile_photo
            }
        }
        // console.log(body, "hdhsfhdghfgdshgfgdsgfhdghgfdghgfhdg")
        const result = await apiFunctions.updateUser(id, body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        } else {
            setIsLoading(false)
            setEdit(false)
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
    }


    useEffect(() => {
        getDetail()
    }, [])

    // console.log(selectedUsers, "user is here")
    return (
        <>
        <ToastContainer autoClose={1000} />
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                    <h3>Suggestion</h3>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} lg={5} item >
                                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" , marginTop : "10px"}}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Suggested By</div>
                                            <div><input type="text" className="popInput1" name="user_name" id="user_name"  value={obj.user.user_name}  readOnly /></div>
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Country</div>
                                            <div>
                                                <input type="text" className="popInput1" name="country" id="country" value={obj.country}  readOnly  /></div>
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Place</div>
                                            <div>
                                                <input type="text" className="popInput1" name="place" id="place" value={obj.place}  style={{width :"950px"}}  readOnly /></div>
                                          
                                        </div>
                                    </Grid>
                                    <Grid item style={{ width: "100%", paddingTop: "15px", paddingLeft: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Description</div>
                                            <div>
                                                <textarea type="text" className="popInput1" name="description" id="description" value={obj.description}  style={{width :"950px", height : "250px"}}  readOnly /></div>
                                          
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid><Grid xs={12} sm={12} md={6} lg={7} item >
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start" >
                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: "flex-start", gap: "10px", alignItems: "flex-start", }}>
                                            <div className="popText">Suggested By Email</div>
                                            <input type="text" placeholder="Enter Event location" className="popInputeventDetail" name="place" id="place"  value={obj.user.email} onChange={addata}  readOnly /></div>
                                      
                                    </Grid>

                                    <Grid item style={{ width: "100%", marginLeft: "70px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Annual Emission Of CO<sub>2</sub></div>
                                            <div><input type="text" className="popInputeventDetail" name="description" id="description"  value={obj.annual_emission_of_co2}   readOnly /></div>
                                           
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </BackPaper>
                </>}

        </>
    )
}

export default SuggestionDetail