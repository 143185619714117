import { AddToPhotos } from '@mui/icons-material'
import { Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import apiFunctions from '../../api/apiFunctions';
import Toptag from '../../components/toptag/Toptag'
import Loader from '../../components/Loader/Loader'


const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


function FeedbackDetail() {

    const location = useLocation();
    // console.log(location, "location---------------------")
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [image, setImage] = useState("")
    const [image2, setImage2] = useState("")
    const [error, setError] = useState(false)
    const [obj, setObj] = useState({})

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


    const imageChange = (e) => {

        setImage2(e.target.files[0])
        const file = e.target.files[0];
        console.log(file, "file**********************")
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
        } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
            setImage2("")
            setError(true)
        } else {
            setError(true)
        }

    }
    // console.log(image2, "imatge2222222222222222222222222222222")
    // console.log(image, "imageggggggggggggggggggggggg")


    const handlecChange = () => {
        setEdit(true)
    }

    const addata = (e) => {
        console.log(e.target.value, "value----------+++++++++++++++")
        const { name, value } = e.target;
        setObj((obj) => ({
            ...obj,
            [name]: value,
        }));
    }

    const getFaqDetail = async (previous) => {
        const result = await apiFunctions.getFeedback(location.state.id);
        console.log("hellogetUserDeatil-----------", result)
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setObj(result.data.data)
            await delay(1000);
            setIsLoading(false)
        }
    }


    const updateUserProfile = async (event) => {
        const id = location.state.id;
        event.preventDefault();

        let body = {
                question: obj.question,
                answer: obj.answer,
            }

        const result = await apiFunctions.updateFaq(id, body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        } else {
            setIsLoading(false)
            setEdit(false)
            setObj(result.data.data)
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
    }


    useEffect(() => {
        getFaqDetail()
    }, [])
    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <BackPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Feedback Detail</Grid>
                        </Grid>
                        <hr></hr>

                        <Grid container spacing={2}>    
                        <Grid item style={{marginLeft: "15px"}}>Name</Grid>
                            <Grid item style={{ width: "95%", marginLeft: "15px"}}>
                                <input type="text" value={obj.user.user_name}
                                    name="user_name"  className="detailBarInput" readOnly/>
                            </Grid>
                            <Grid item style={{marginLeft: "15px"}}>Email</Grid>
                            <Grid item style={{ width: "95%", marginLeft: "15px"}}>
                                <input type="text" value={obj.user.email}
                                    name="email"  className="detailBarInput" readOnly/>
                            </Grid>                        
                            <Grid item style={{marginLeft: "15px"}}>Title</Grid>
                            <Grid item style={{ width: "95%", marginLeft: "15px"}}>
                                <input type="text" value={obj.title}
                                    name="title"  className="detailBarInput" readOnly/>
                            </Grid>

                            <Grid item style={{marginLeft: "15px"}}>Concern</Grid>
                            <Grid item style={{ width: "95%", marginLeft: "15px" }}>
                                <textarea type="text" value={obj.concern}
                                    name="concern"  className="detailBarInput" style={{height : "180px"}} readOnly/>
                            </Grid>
                        </Grid>
                        <Grid item>
                                {edit ? <button className='newBtn' onClick={updateUserProfile}>Save</button> : null}
                            </Grid>

                    </BackPaper>
                </>)}
        </>
    )
}

export default FeedbackDetail