import { AddToPhotos,  } from '@mui/icons-material'
import { Chip, Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import apiFunctions from '../../api/apiFunctions';
import Toptag from '../../components/toptag/Toptag'
import "./userProfile.css"
import Loader from '../../components/Loader/Loader'



const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const UserProfile = ({ objs }) => {

    const location = useLocation();
    // console.log(location, "location---------------------")
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [image, setImage] = useState("")
    const [image2, setImage2] = useState("")
    const [error, setError] = useState(false)
    const [obj, setObj] = useState({})


    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));



    const imageChange = (e) => {

        setImage2(e.target.files[0])
        const file = e.target.files[0];
        console.log(file, "file**********************")
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
        } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
            setImage2("")
            setError(true)
        } else {
            setError(true)
        }

    }
    // console.log(image2, "imatge2222222222222222222222222222222")
    // console.log(image, "imageggggggggggggggggggggggg")


    const handlecChange = () => {
        setEdit(true)
    }

    const addata = (e) => {
        console.log(e.target.value, "value----------+++++++++++++++")
        const { name, value } = e.target;
        setObj((obj)=>({
            ...obj,
            [name]: value,
        }));
    }

    // console.log(updatedObject, "updated+++++++++++++++++++++++++++++++++++-----------------------------")


    const getUserDeatil = async (previous) => {
        // console.log("hellogetUserDeatil")
        const result = await apiFunctions.getUserProfile(location.state.id);
        console.log(result, "result--------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setObj(result.data.data)
            setImage(result.data.data.profilePhoto)
            await delay(1000);
            setIsLoading(false)

        }
    }
    // console.log(obj, "objetttttttttttttttttttttttttttttttttttttttttttt+++++++++++")

    const updateUserProfile = async (event) => {
        const id = location.state.id;
        event.preventDefault();
        localStorage.removeItem('image')
        const formData = new FormData()
        formData.append("image", image2)
        let body;
        console.log(formData, "formData9999999999999")
        const profilePhotoPath = await apiFunctions.uploadPhoto(formData)
        console.log(profilePhotoPath, "profilePhotoPath-------")
        if (!profilePhotoPath.data.status) {
            body = {
                user_name: obj.user_name,
                email: obj.email,
                skills: obj.skills,
                profilePhoto: image
            }
        } else {
            body = {
                user_name: obj.user_name,
                email: obj.email,
                skills: obj.skills,
                profilePhoto: profilePhotoPath.data.data.profile_photo
            }
        }
        console.log(body, "hdhsfhdghfgdshgfgdsgfhdghgfdghgfhdg")
        const result = await apiFunctions.updateUser(id, body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        } else {
            setIsLoading(false)
            setEdit(false)
            getUserDeatil()
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
    }


    useEffect(() => {
        getUserDeatil()
    }, [])

    // console.log(selectedUsers, "user is here")
    return (
        <>
        <ToastContainer autoClose={1000} />
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>User Profile Details</Grid>
                            <Grid item>
                                {!edit ? (
                                    <>
                                        <button className='newBtn' onClick={handlecChange}>Update</button>
                                        {/* <button className='newBtn' onClick={openDialog}>Add friends</button> */}
                                    </>) :
                                    null
                                }
                            </Grid>
                        </Grid>
                        <hr></hr>

                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} lg={5} item >
                                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                    <Grid item>

                                        {image !== '' ? (
                                            <>
                                                <img src={image} alt="user" className='imgCont1' />
                                            </>
                                        ) : (
                                            <img src="/images/blank_pic.png" className='imgCont1' alt="" />

                                        )}
                                        <Grid item>
                                            {edit ? <div className="image-upload uploadicon">
                                                <label htmlFor="image">
                                                    <Tooltip title="Change user profile pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                                                </label>

                                                <input id="image" onChange={imageChange} type="file" name='image' accept="image/*" />
                                            </div> : null}
                                        </Grid>
                                        {error ? <><Grid item className='fileErr'>Invalid file format. Only JPG, PNG, and other image formats are accepted</Grid>
                                            <Grid item><button className='newBtn' onClick={() => setError(false)}>Ok</button></Grid></> : null}
                                    </Grid>
                                    <Grid item>
                                        {edit ? <button className='newBtn' onClick={updateUserProfile}>Save</button> : null}
                                    </Grid>
                                </Grid>
                            </Grid><Grid xs={12} sm={12} md={6} lg={7} item>
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item>Name</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={obj.user_name}
                                            name="user_name" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>email</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={obj.email}
                                            name="email" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Status</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.isVerified === false ? ('Not Active User') : (obj.isBlocked === false ? 'Active User' : 'Blocked User')} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Skills</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={obj.skills}
                                            name="skills" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                    {/* <Grid item>User Points</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.points} className="detailBarInput" />
                                    </Grid> */}
                                    {/* <Grid item>Premium Status</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        {obj.premiumStatus === false ?
                                            <>
                                                <Chip label="free" className="statusChip" icon={<FreeBreakfastSharp className='FreeBreakfastSharp' />} />

                                            </>
                                            :
                                            <>
                                                <Chip label="Premium" className="statusChipPr" icon={<WorkspacePremium className='WorkspacePremium' />} />

                                            </>
                                    </Grid>} */}
                                    <Grid item>Role</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled value={obj.role} className="detailBarInput" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                      

                    </BackPaper>
                </>}

        </>
    )
}

export default UserProfile