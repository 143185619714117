import html2canvas from 'html2canvas';
import { Paper, Dialog, DialogContent, DialogTitle, Button, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
// import "./events.css"
import apiFunctions from '../../api/apiFunctions'


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


const AddEvent = () => {
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();
    const [err, setErr] = useState(false);
    const [create, setCreate] = useState(false);
    const [place, setPlace] = useState('');
    const [obj, setObj] = useState(
        { title: '', place: '', latitude: 0, longitude: 0, map_url: '', country: '', date_times: [{ date: '', slots: [{ startTime: '', endTime: '' }] }], world_rank: 0, required_people: 100, live_expected_people: 50, annual_emission_of_co2: '' }
    );
    const [profilePhotoFile, setProfilePhotoFile] = useState(null);
    const [showImage, setShowImage] = useState(null);
    const mapRef = useRef(null);
    const inputRef = useRef(null);
    const markersRef = useRef([]);
    const [showDateTimePopup, setShowDateTimePopup] = useState(false);
    const [dateTimes, setDateTimes] = useState([{ date: '', slots: [{ startTime: '', endTime: '' }] }]);

    const handleAddDateTime = () => {
        const updatedDateTimes = [...obj.date_times];
        const newDateTime = { date: '', slots: [{ startTime: '', endTime: '' }] };
        updatedDateTimes.push(newDateTime);
        setObj({
            ...obj,
            date_times: updatedDateTimes
        })
    };


    const handleAddSlot = (dateIndex) => {
        const updatedDateTimes = [...obj.date_times];
        const newSlot = { startTime: '', endTime: '' };
        updatedDateTimes[dateIndex].slots.push(newSlot);
        setObj({
            ...obj,
            date_times: updatedDateTimes
        })
    };

    const handleDeleteSlot = (dateIndex, slotIndex) => {
        const updatedDateTimes = [...obj.date_times];
        updatedDateTimes[dateIndex].slots.splice(slotIndex, 1);
        setObj({
            ...obj,
            date_times: updatedDateTimes
        })
    };


    const handleDate = (index, field, value) => {
        const updatedDateTimes = [...obj.date_times];
        updatedDateTimes[index][field] = value;
        setDateTimes(updatedDateTimes);
        setObj({
            ...obj,
            date_times: updatedDateTimes
        })
    };

    const handleDateTimeChange = (dateIndex, slotIndex, field, value) => {
        const updatedDateTimes = [...obj.date_times];
        updatedDateTimes[dateIndex].slots[slotIndex][field] = value;
        setDateTimes(updatedDateTimes);
        setObj({
            ...obj,
            date_times: updatedDateTimes,
        });
    };

    const handleSaveDateTime = () => {
        // Process the selected dateTimes as needed
        console.log(obj.date_times);
        setShowDateTimePopup(false); // Close the DateTimePopup after saving
    };

    const handleDeleteDateTime = (index) => {
        const updatedDateTimes = [...obj.date_times];
        updatedDateTimes.splice(index, 1);
        setDateTimes(updatedDateTimes);
        setObj({
            ...obj,
            date_times: updatedDateTimes
        })
    };


    console.log(obj, "obj--------------------------------33")

    const initMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 37.7749, lng: -122.4194 },
            zoom: 12,
        });
        const input = inputRef.current;
        const searchBox = new window.google.maps.places.SearchBox(input);
        map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds());
        });
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            console.log(places, "places")
            if (places.length === 0) {
                // Display error message or handle invalid place selection
                setErr(true);
            } else {
                setPlace(places[0].formatted_address)
                setObj({
                    ...obj,
                    place: places[0].formatted_address,
                    latitude: places[0].geometry.location.lat(),
                    longitude: places[0].geometry.location.lng(),
                    map_url: places[0].url
                });

                // Clear error message if previously set
                setErr(false);
            }
            if (places.length === 0) {
                return;
            }

            markersRef.current.forEach((marker) => {
                marker.setMap(null);
            });
            markersRef.current = [];
            const bounds = new window.google.maps.LatLngBounds();
            places.forEach((place) => {
                if (!place.geometry) {
                    console.log('Returned place contains no geometry');
                    return;
                }
                markersRef.current.push(
                    new window.google.maps.Marker({
                        map: map,
                        title: place.name,
                        position: place.geometry.location,
                    })
                );
                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });
    };

    console.log(place == obj.place, "object-----------------------------")

    useEffect(() => {
        initMap()
    }, [inputRef]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        // const formData = new FormData();
        // formData.append('image', profilePhotoFile);
        // let userObj;
        // const profilePhotoPath = await apiFunctions.uploadPhoto(formData)
        // console.log(profilePhotoPath);
        // if (!profilePhotoPath.data.status) {
        //     if (obj.name === '' || obj.countryCode === '' || obj.mobileNumber === '') {
        //         setErr(true);
        //     }
        //     else {
        //         userObj = {
        //             name: obj.name,
        //             countryCode: obj.countryCode,
        //             mobileNumber: obj.mobileNumber
        //         }
        //     }
        // } else {
        //     if (obj.name === '' || obj.countryCode === '' || obj.mobileNumber === '') {
        //         setErr(true);
        //     } else {
        //         userObj = {
        //             name: obj.name,
        //             countryCode: obj.countryCode,
        //             mobileNumber: obj.mobileNumber,
        //             profilePhoto: profilePhotoPath.data.data.profile_photo
        //         }
        //     }
        // }
        console.log(obj.place === place)
        let userObj;
        if (obj.title === '' || obj.place === '' || obj.country === '' || obj.annual_emission_of_co2 === '' || obj.date_times[0].date == '' || obj.date_times[0].slots[0].startTime == '', obj.date_times[0].slots[0].endTime == '') {
            setErr(true);
        } else if (obj.place !== place) {
            setErr(true);
        }
        else {
            userObj = {
                title: obj.title, place: obj.place, latitude: obj.latitude, longitude: obj.longitude, map_url: obj.map_url, date_times: obj.date_times, country: obj.country, world_rank: obj.world_rank, required_people: obj.required_people, live_expected_people: obj.live_expected_people, annual_emission_of_co2: obj.annual_emission_of_co2
            }
        }

        if (!err) {
            const result = await apiFunctions.createEvent(userObj);
            console.log(result, "rsult8888888888888888")
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear();
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                setObj(
                    { title: '', place: '', latitude: 0, longitude: 0, map_url: '', date_times: [{ date: '', slots: [{ startTime: '', endTime: '' }] }], country: '', world_rank: 0, required_people: 100, live_expected_people: 50, annual_emission_of_co2: '' }
                )
                toast.success("Event created succesfully!")
                setErr(false)
            }
        }
    };

    const addUserData = (e) => {
        setErr(false)
        const { name, value } = e.target;
        if (name === 'image') {
            const file = e.target.files[0];
            setProfilePhotoFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setShowImage(reader.result);
            };
        } else if (name === 'world_rank' || name === 'required_people' || name === 'live_expected_people') {
            setObj({
                ...obj,
                [name]: parseInt(value),
            });
        } else {
            setObj({
                ...obj,
                [name]: value,
                date_times: obj.date_times,
            });
            console.log(obj, "object--------------------------------------------------")
        }
    }

    // console.log(obj, "onj99999999999999999999999999")

    const captureMapScreenshot = async (map) => {
        try {

            // Capture the screenshot using html2canvas
            const canvas = await html2canvas(map);
            console.log(canvas, "canvas555555555555")

            // Convert the canvas to a data URL (base64)
            const screenshotDataUrl = canvas.toDataURL('image/png');

            return screenshotDataUrl;
        } catch (error) {
            console.error('Error capturing map screenshot:', error);
            return null;
        }
    };

    // const Submit = async (event) => {
    //     event.preventDefault();

    //     const mapScreenshot = await captureMapScreenshot();

    //     console.log(mapScreenshot, "mapScreenshotmapScreenshotmapScreenshotmapScreenshotmapScreenshot");
    // };

    // console.log(showImage, "showImage9+999999999999999999999")

    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <NavPaper>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='popHeading'>Add New Event</div>
                        </div>
                        <div className='conatiner-fluid'>
                            <div className='row'>
                                <div id='map' ref={mapRef} style={{ height: '150px', width: "93%", marginBottom: "10px", marginTop: "10px", marginRight: '40px', marginLeft: '40px' }} />
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", gap: "10px", alignItems: "center" }}>
                                        <div className="popText">Place</div>
                                        <input type="text" ref={inputRef} placeholder="Enter Event location" className="popInput1" name="place" id="place" onChange={addUserData} value={obj.place} />
                                        {err && (obj.place === '' || obj.place !== place) ?
                                            <div className="errText">
                                                {obj.place === '' ? "Required!" : "Please select a valid place!"}
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Country</div>
                                            <div><input type="text" className="popInput1" name="country" id="country" onChange={addUserData} value={obj.country} /></div>
                                            {err && obj.country === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>

                                </div>

                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>

                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Date Of Event</div>
                                            <div>
                                                <button variant='primary' color='blue' onClick={() => setShowDateTimePopup(true)}>Add Date and Time</button>
                                            </div>
                                            {err && (dateTimes[0].date == '' || dateTimes[0].slots[0].startTime == '', dateTimes[0].slots[0].endTime == '') ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Event Title</div>
                                            <div><input type="text" className="popInput1" name="title" id="title" onChange={addUserData} value={obj.title} /></div>
                                            {err && obj.title === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Country's World Rank</div>
                                            <div>
                                                <input type="number" min="0" className="popInput1" name="world_rank" id="world_rank" onChange={addUserData} value={obj.world_rank} /></div>
                                            {err && obj.world_rank === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>


                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Required People</div>
                                            <div>
                                                <input type="number" min="0" className="popInput1" name="required_people" id="required_people" onChange={addUserData} value={obj.required_people} /></div>
                                            {err && obj.required_people === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Live Expected People</div>
                                            <div>
                                                <input type="number" min="0" className="popInput1" name="live_expected_people" id="live_expected_people" onChange={addUserData} value={obj.live_expected_people} /></div>
                                            {err && obj.live_expected_people === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>

                                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Annual Emission Of CO<sub>2</sub></div>
                                            <div>
                                                <input type="text" className="popInput1" name="annual_emission_of_co2" id="annual_emission_of_co2" onChange={addUserData} value={obj.annual_emission_of_co2} /></div>
                                            {err && obj.annual_emission_of_co2 === '' ? <div className="errText">Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                            <div>
                                <button className='popBtn' onClick={handleSubmit}>Create</button>
                            </div>
                        </div>

                        {/* DateTimePopup inside AddEvent popup */}
                        {showDateTimePopup && (
                            <Dialog open={showDateTimePopup} onClose={() => setShowDateTimePopup(false)}>
                                <DialogTitle>Add Schedules</DialogTitle>
                                <DialogContent style={{ width: '550px' }}>
                                    <div style={{ paddingTop: '10px', paddingBottom: '10px', }}>
                                        {obj.date_times.map((dateTime, index) => (
                                            <div key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ flex: 1, paddingRight: '10px', marginRight: '10px' }}>
                                                        <label>Date:</label>
                                                        <input type="date" style={{ marginLeft: '20px', paddingLeft: '5px', height: '30', width: '' }} value={dateTime.date} onChange={(e) => handleDate(index, 'date', e.target.value)} />
                                                    </div>

                                                    <div style={{ marginRight: '20px', marginBottom: '4px' }}>
                                                        <Tooltip title="Add slot to schedule">
                                                            <AddCircleOutlineIcon variant="outlined" color="primary" onClick={() => handleAddSlot(index)}>+</AddCircleOutlineIcon>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Button variant="outlined" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '2px', paddingBottom: '2px' }} color="error" onClick={() => handleDeleteDateTime(index)}>
                                                            Delete Schedule
                                                        </Button>
                                                    </div>
                                                </div>
                                                {/* Display existing slots */}
                                                <div>
                                                    {dateTime.slots.map((slot, slotIndex) => (

                                                        <div key={slotIndex} style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                                                            <label>Start Time:</label>
                                                            <input type="time" style={{ width: "125px", marginLeft: '5px' }} value={slot.startTime} onChange={(e) => handleDateTimeChange(index, slotIndex, 'startTime', e.target.value)} />
                                                            <label style={{ marginLeft: "20px" }}>End Time:</label>
                                                            <input type="time" style={{ width: "125px", marginLeft: '5px' }} value={slot.endTime} onChange={(e) => handleDateTimeChange(index, slotIndex, 'endTime', e.target.value)} />

                                                            {/* Button to delete slot */}
                                                            <Tooltip title="Delete slot">
                                                                <DeleteIcon
                                                                    color="error"
                                                                    style={{ marginLeft: "10px" }}
                                                                    onClick={() => handleDeleteSlot(index, slotIndex)}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    ))}
                                                </div>

                                                {err && (dateTime.date === '' || dateTime.slots.some((slot) => slot.startTime === '' || slot.endTime === '')) ? (
                                                    <div className="errText">Required!</div>
                                                ) : null}

                                                {/* Button to add new slot */}


                                                {/* Button to delete date */}
                                                {/* <Button variant="outlined" color="error" onClick={() => handleDeleteDateTime(index)}>
                                                    Del Date and time
                                                </Button> */}
                                            </div>
                                        ))}
                                    </div>
                                    {/* Button to add new date and time entry */}
                                    <Button variant="contained" onClick={handleAddDateTime} style={{ marginBottom: '16px' }}>
                                        Add More Schedule
                                    </Button>

                                    {/* Button to save changes */}
                                    <Button variant="contained" onClick={handleSaveDateTime} style={{ marginBottom: '16px', left: '250px' }}>
                                        Save
                                    </Button>
                                </DialogContent>
                            </Dialog>
                        )}
                    </NavPaper>

                </>
            )}
        </>
    )
}
export default AddEvent









