import html2canvas from 'html2canvas';
import { Paper, Dialog, DialogContent, DialogTitle, Button } from '@mui/material'
import { styled } from '@mui/system'
import UploadFile from '@mui/icons-material/UploadFile';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
// import "./events.css"
import apiFunctions from '../../api/apiFunctions'


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


const AddNews = () => {
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();
    const [err, setErr] = useState(false);
    const [create, setCreate] = useState(false);
    const [obj, setObj] = useState(
        { title: '', description : '', news_photo : ''}
    );
    const [profilePhotoFile, setProfilePhotoFile] = useState(null);
    const [showImage, setShowImage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('image', profilePhotoFile);
        let newsObj;
        const profilePhotoPath = await apiFunctions.uploadNewsPhoto(formData)
        console.log(profilePhotoPath);
        if (!profilePhotoPath.data.status) {
            if (obj.title === '' || obj.description === '' ) {
                setErr(true);
            }
            else {
                newsObj = {
                    title: obj.title,
                    description: obj.description,
                }
            }
        } else {
            if (obj.title === '' || obj.description === '') {
                setErr(true);
            } else {
                newsObj = {
                    title: obj.title,
                    description: obj.description,
                    news_photo: profilePhotoPath.data.data.profile_photo
                }
            }
        }

        const result = await apiFunctions.createNews(newsObj);
        console.log(result, "rsult8888888888888888")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setObj(
                { title: '', description: '', news_photo: ''}
            )
            setShowImage(null)
            toast.success("News Added succesfully!")
        }
    };

    const addUserData = (e) => {
        setErr(false)
        const { name, value } = e.target;
        if (name === 'image') {
            const file = e.target.files[0];
            setProfilePhotoFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setShowImage(reader.result);
            };
        } else {
            setObj({
                ...obj,
                [name]: value,
            });
        }
    }

    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <NavPaper>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='popHeading'>Add News</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "start", gap: "30px", alignItems: "start" }}>

                                    <div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start", marginLeft:"20px"}}>
                                            <div className="popText">Heading</div>
                                            <div><input type="text" className="popInput2" name="title" id="title" onChange={addUserData} value={obj.title}/></div>
                                            {err && obj.title === '' ? <div className="errText">Heading is required!</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "start", gap: "30px", alignItems: "start" }}>

                                    <div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start", marginLeft:"20px"}}>
                                            <div className="popText">Description</div>
                                            <div>
                                                <textarea type="text" className="popInput3" name="description" id="description" onChange={addUserData} value={obj.description}/></div>
                                            {err && obj.description === '' ? <div className="errText"> Description is Required!</div> : null}
                                        </div>
                                    </div>
                                </div>
                
                                <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: "45px", gap: "30px", alignItems: "start" }}>
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                                            <div className="popText">Profile Photo</div>
                                            {showImage && (
                                                <img src={showImage} alt="selectedImage" style={{ width: "100px" }} />
                                            )}
                                            <label htmlFor='image'>
                                                <UploadFile className='uploddIcon' />
                                            </label>
                                            <div>
                                                <input type="file" className="popInputPhoto" id="image" name="image" onChange={addUserData} style={{ display: "none" }} accept="image/*" /></div>
                                            {/* {err && obj.profilePhoto === '' ? <div className="errText">Email is required!</div> : null}
                                            {err && obj.profilePhoto !== '' && !obj.profilePhoto.includes('@') ? <div className="errText">Email must contain "@"!</div> : null} */}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                            <div>
                                <button className='popBtn' onClick={handleSubmit}>ADD</button>
                            </div>
                        </div>
                    </NavPaper>

                </>
            )}
        </>
    )
}
export default AddNews









