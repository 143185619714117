import React from 'react';
import './navbar.css';
import apiFunctions from '../../api/apiFunctions';
import { NavLink, useNavigate} from 'react-router-dom';
import { NotificationsNone, Settings } from '@mui/icons-material';
import {  Button, Menu , MenuItem } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Person , Key, Logout} from '@mui/icons-material';

export default function Topbar() {

	const navigate = useNavigate();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutClick = async(e) => {
        e.preventDefault();
        try {
            const res = await apiFunctions.logout();
            if(res.data.status){
            toast.success('Logout Successful !', {
                position: toast.POSITION.TOP_RIGHT
            });
            localStorage.clear('token');
            navigate('/')
                setTimeout(window.location.reload.bind(window.location), 10);
                
            }else{
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const profilePhoto = localStorage.getItem('profilePhoto')
    // console.log(profilePhoto,"profilePhoto---------------------------------------")
    return (
        <div className="topbarWrapper">

            <div className="logoContainer">
            <NavLink to= '/home' className='link'>
                <span className="spanName">Climate&nbsp;Meet</span>
                </NavLink>
            </div>
            <div className="topRight">
                {/* <div className="topbarIconContainer">
                    <NotificationsNone sx={{ color: 'white' }} />
                    <span className="topIconbadge">2</span>
                </div>
                <div className="topbarIconContainer">
                    <Settings sx={{ color: 'white' }} />
                </div> */}

                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{shadow: 'none'}}
                >
                    <img src={profilePhoto} alt="logo" className='topAvatar' />

                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <NavLink to='/profile' className='link'>
                        <div>
                    <MenuItem onClick={handleClose}><Person className='menuIcons'/>Profile</MenuItem>
                        </div>
                    </NavLink>
                    <NavLink to='/change_Password' className='link'>
                        <div>
                    <MenuItem onClick={handleClose}><Key className='menuIcons' />Change Password</MenuItem>
                        </div>
                        </NavLink>
                    <MenuItem onClick={logoutClick}><Logout className='menuIcons'/>Logout</MenuItem>
                    <ToastContainer/>
                </Menu>
            </div>
        </div>
    )
}
