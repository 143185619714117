import React, { useState, useEffect } from "react";
// import Server from "../../server/Server";
import {DNA} from 'react-loader-spinner'
import './SetDistance.css'
import apiFunctions from '../../api/apiFunctions';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function SetDistance() {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0); // Initial value for the slider (e.g., 5000)
  const [arrowPosition, setArrowPosition] = useState(0); // Initial position for the arrow
  const [buttonHovered, setButtonHovered] = useState(false);
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("x_token");


  function metersToMiles(meters) {
    // Conversion factor: 1 meter = 0.001 km
    var km = meters * 0.001;
    return km;
}

function kmToMeters(km) {
    // Conversion factor: 1 km = 1000 meters
    var meters = km * 1000;
    return meters;
}


  const getProfile = async () => {
    try {
        const result = await apiFunctions.getAdminProfile()
        console.log(result, "result----------------------3333333")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear('token');
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }

            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setLoading(false)
            setValue(Math.ceil(metersToMiles(result.data.data.distance)))
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}
console.log(value, "detail66666666666")


  useEffect(() => {
    getProfile();
  }, []);

  const editRadius = async () => {
    const radius = kmToMeters(value) ; // Convert miles to meters for the API request

    try {
      const result = await apiFunctions.updateDistance({ distance: radius });
      console.log(result, "result-------------------------")
      if (!result.data.status) {
        if (result.data.code === 401) {
            localStorage.clear('token');
            navigate("/login")
            toast.info("Session Expired Please login again!!")
        }

        toast.error(result.data.message, {
            position: toast.POSITION.TOP_RIGHT
        });
    } else {
        setLoading(false)
        toast.success(result.data.message, {
          position: toast.POSITION.TOP_RIGHT
      });
    }
    } catch (error) {
      console.error("Error editing radius:", error);
      toast.error("An error occurred while editing the radius.");
    }
  };


  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const handleButtonClick = () => {
    // Call the editRadius function when the button is clicked
    editRadius();
    document.body.style.cursor = "progress";

    setTimeout(() => {
      document.body.style.cursor = "auto";
    }, 1000);
  };

  const arrowStyle = {
    left: `${arrowPosition}%`,
  };

  const rotatedArrowStyle = {
    transform: "rotate(-90deg)",
  };

  const buttonStyle = {
    background: buttonHovered ? "#ff5722" : "#007bff",
  };

  return (
    <>
    <ToastContainer autoClose={1000} />
    <div className="slider-container">
    
   {loading ? (
        <div className="loading-animation">
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <>
          <h2>Radius (KM)</h2>
          <div className="slider-value">
            <p>
              Radius: <span style={{ color: "#ff5722" }}>{value}</span> km
            </p>
          </div>
          <div className="slider-wrapper">
            <input
              type="range"
              min="0"
              max="10000"
              value={value}
              onChange={handleChange}
              className="custom-slider"
            />
          </div>
          <button
            className="submit-button"
            style={buttonStyle}
            onClick={handleButtonClick}
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
          >
            Submit
          </button>
        </>
      )}
    </div>
    </>
  );
}

export default SetDistance;