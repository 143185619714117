import {Suspense} from "react"
import { Routes, Route} from "react-router-dom"
import routes from "./routes"


export default function Rounting () {
      return(
        <Routes>
            {routes.map((e)=>{
                return <Route path={e.path} key={e.path}  element={<Suspense >{e.Component}</Suspense>}/>
            })}
        </Routes>
      )
}