import React, { useEffect, useState } from "react";
import Toptag from "../../components/toptag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import apiFunctions from "../../api/apiFunctions";



const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ActionSubstant = () => {

    const [obj, setObj] = useState({});
    const [id, setId] = useState('')
    const navigate = useNavigate();
    const [dis, setDis] = useState(true);

    const handleChange = e => {
        const { name, value } = e.target
        setObj({
            ...obj,
            [name]: value
        })
    };


    const editFunction = () => {
        setDis(false)
    }

    const updateContent = async () => {
        let data = {
            _id: id,
            content: obj.content,
            example: obj.example
        }

        try {
            let result = await apiFunctions.updateTerms(data)
            if (!result.data.status) {
                if (result.data.code == 401) {
                    localStorage.clear('token');
                    sessionStorage.clear('token');
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });

            } else {
                toast.success("Successfully updated!")
                setDis(true)
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const getActions = async () => {
        try {
            let result = await apiFunctions.getSubstantAction()
            console.log(result, "resuklt-----------")
            if (!result.data.status) {
                if (result.data.code == 401) {
                    localStorage.clear('token');
                    sessionStorage.clear('token');
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });

            } else {
                setObj(result.data.data)
                setId(result.data.data._id)
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    console.log(obj, "obj subbstant action")

    useEffect(() => {
        getActions()
    }, [])


    return (
        <>
            <div className='container-fluid'>
                <Toptag />
                <ToastContainer autoClose={1000} />
                <BackPaper>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Substantiated</Grid>
                        <Grid item>
                            {dis ? (
                                <>
                                    <button className='newBtn' onClick={editFunction}>Update</button>
                                </>) :
                                null
                            }
                        </Grid>
                    </Grid>
                    <hr></hr>
                    <div>
                        <div >
                            <label>Substantiated</label>
                        </div>
                        <textarea style={{ height: "200px", width: "100%" }} name="content" value={obj.content} onChange={handleChange} disabled={dis ? true : false} />
                    </div>
                    <div>
                        <div>
                            <label>Example</label>
                        </div>
                        <textarea style={{ height: "200px", width: "100%" }} name="example" value={obj.example} onChange={handleChange} disabled={dis ? true : false} />
                    </div>
                    {!dis ?
                        <Grid container style={{ marginTop: "10px" }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <button className="newBtn" onClick={updateContent}>SUBMIT</button>
                            </Grid>
                        </Grid> : null
                    }
                </BackPaper>
            </div>
        </>
    )
}

export default ActionSubstant