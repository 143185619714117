import {
  Add,
  Block,
  Cancel,
  DeleteOutline,
  DoneAll,
  FreeBreakfastSharp,
  RemoveCircleOutline,
  RemoveRedEye,
  Search,
  WorkspacePremium,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import Toptag from "../../components/toptag/Toptag";
import "./users.css";
import apiFunctions from "../../api/apiFunctions";

const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const RemovedUsers = () => {
  const [all, setAll] = useState([]);
  const [usersCount, setUsersCount] = useState();
  const navigate = useNavigate();
  const [limit, setLimit] = useState("5");
  const [skip, setSkip] = useState("0");
  const [page, setPage] = useState("1");
  const [searchKey, setKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openAddDialogue, setOpenAddDialogue] = React.useState(false);
  const [id, setId] = useState();
  const [status, setStatus] = useState();

  //   const handleBlockOpen = (id, status) => {
  //     setId(id)
  //     setStatus(status)
  //     setOpen(true);
  //   };

  //   const handleBlock = () => {
  //     setOpen(false);
  //   };

  const handleOpenAddDialogue = (id) => {
    setId(id);
    setOpenAddDialogue(true);
  };

  const handleCloseAddDialogue = () => {
    setOpenAddDialogue(false);
  };

  const searchHandle = (event) => {
    setKey(event.target.value);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getAll = async () => {
    if (searchKey) {
      setIsLoading(false);
    }

    const result = await apiFunctions.getAllRemovedUsers(
      limit,
      searchKey,
      page
    );
    // console.log(result, "result of deleted users+--------------------+++++++++++")
    if (!result.data.status) {
      if (result.data.code === 401) {
        localStorage.clear();
        navigate("/login");
        toast.info("Session Expired Please login again!!");
      }
      toast.error(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setAll(result.data.data.data);
      setUsersCount(result.data.data.total);
      await delay(1000);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchKey === "" && limit === "" && page === "") {
      setIsLoading(true);
    }
    getAll();
  }, [searchKey, limit, page]);

  const addRemovedUser = async (id) => {
    const body = {
      _id: id,
    };
    const result = await apiFunctions.removeUser(body);
    if (!result.data.status) {
      if (result.data.code === 401) {
        localStorage.clear();
        navigate("/login");
        toast.info("Session Expired Please login again!!");
      }
      toast.error(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setOpenAddDialogue(false);
      getAll();
      toast.success(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //   const blockUser = async (id) => {
  //     const result = await apiFunctions.blockUser(id);
  //     if (result.data.code === 200) {
  //       setOpen(false);
  //       getAll();
  //       toast.success("Successfully updated!");
  //     } else if (result.data.code === 201) {
  //       toast.error("Please try again!")
  //     } else if (result.data.code === 401) {
  //       localStorage.clear('admintoken');
  //       sessionStorage.clear('admintoken');
  //       navigate("/")
  //       toast.info("Session Expired Please login again!!")
  //     }
  //   }

  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0);
      setLimit(usersCount);
    } else {
      setLimit(e.target.value);
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * limit);
    setPage(page);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Toptag />
          <ToastContainer />
          <NavPaper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div></div>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "grey",
                    }}
                  >
                    Total Deleted Users - {usersCount}
                  </div>
                </Grid>
                <Grid item>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "grey",
                    }}
                  >
                    Row Per Page -{" "}
                  </div>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard-label"
                      value={limit}
                      onChange={handleChange}
                    >
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="30">30</MenuItem>
                      <MenuItem value={usersCount}>All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <div className="searchBar">
                    <input
                      type="text"
                      placeholder="Search... "
                      onChange={searchHandle}
                      id="searchtext"
                      className="searchBarInput"
                    />
                    <Search className="searchIcon" />
                  </div>
                </Grid>
              </Grid>
            </div>
          </NavPaper>
          <BackPaper>
            {all.length === 0 ? (
              <Grid
                container
                spacing={2}
                style={{ marginTop: "30px" }}
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item>
                  <img
                    src="/images/nodata.jpg"
                    alt="error-image"
                    style={{ height: "200px", width: "200px" }}
                  />
                </Grid>
                <Grid item>
                  <div className="errorMessage">Data not found!</div>
                </Grid>
              </Grid>
            ) : (
              <>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Joined Since</TableCell>
                      {/* <TableCell>Created By Admin</TableCell> */}
                      <TableCell>Active Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {all.map((element, index) => {
                      let date = new Date(element.createdAt);
                      let monthsArray = [
                        "Jan",
                        "Feb",
                        "March",
                        "Apr",
                        "May",
                        "Jun",
                        "July",
                        "Aug",
                        "Sept",
                        "Oct",
                        "Nov",
                        "Dec",
                      ];
                      let joinedSince = `${date.getDate()} ${
                        monthsArray[date.getMonth()]
                      } ${date.getFullYear()}`;
                      return (
                        <>
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {element.profilePhoto !== "" ? (
                                <img
                                  src={element.profilePhoto}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50px",
                                  }}
                                  alt="user"
                                />
                              ) : (
                                <img
                                  src="/images/blank_pic.png"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50px",
                                  }}
                                  alt=""
                                />
                              )}
                            </TableCell>
                            <TableCell>{element.user_name}</TableCell>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>{joinedSince}</TableCell>
                            {/* <TableCell align="center">
                              {element.isCreatedAdmin === true ?
                                <div className='adminCreation'>
                                  <div><i className="fa-solid fa-user-tie" style={{ fontSize: "14px" }}></i></div>
                                  <div> Administrator</div>
                                </div>
                                :
                                '...'
                              }</TableCell> */}
                            
                            <TableCell>
                              {element.isBlocked === false ? (
                                <Chip
                                  label="Active"
                                  className="statusChipActive"
                                />
                              ) : (
                                <Chip
                                  label="Blocked"
                                  className="statusChipBlock"
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <Tooltip title="Add User To Website">
                                    <Add
                                      className="userListDelete"
                                      onClick={() => {
                                        handleOpenAddDialogue(element._id);
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            )}
            {/* dilogue for block user */}
            {/* <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {status === false ? ("Are you sure want to unblock this user?") : ("Are you sure want to block this user?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleBlock}>Cancel</Button>
                  <Button onClick={() => { blockUser(id) }} >{status === false ? 'unBlock' : 'Block'}</Button>
                </DialogActions>
              </Dialog> */}

            {/* dilogue for delete user */}
            <Dialog
              open={openAddDialogue}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure want to add this user to website?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleCloseAddDialogue}>No</Button>
                <Button
                  onClick={() => {
                    addRemovedUser(id);
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div>
                <Pagination
                  count={Math.ceil(usersCount / limit)}
                  page={Math.floor(skip / limit) + 1}
                  variant="outlined"
                  onChange={skipPagination}
                  shape="rounded"
                />
              </div>
            </div>
          </BackPaper>
        </>
      )}
    </>
  );
};

export default RemovedUsers;
