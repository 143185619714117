import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, UploadFile, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
// import "./users.css"
import apiFunctions from '../../api/apiFunctions'
import { elements } from 'chart.js'
import { URL } from '../../api/apiUrls'


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const Suggestions = () => {
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [usersCount, setUsersCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(5);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForBlock, setOpenDialogForBlock] = React.useState(false);
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [id, setId] = useState()
    const [blockedStatus, setBlockedStatus] = useState()

    const handleBlockOpen = (id, status) => {
        setId(id)
        setBlockedStatus(status)
        setOpenDialogForBlock(true);
    };

    const handleBlockClose = () => {
        setOpenDialogForBlock(false);
    };

    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }


    const getAll = async () => {
        let result = await apiFunctions.getAllSuggestions(limit, searchKey, page);
        console.log(result, "result-------------------999999999")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data);
            setUsersCount(result.data.total_count)
            setIsloading(false)
        }
    }



    useEffect(() => {
        getAll()
    }, [searchKey, limit, page])

    const removeUser = async (id) => {

        const result = await apiFunctions.removeSuggestion(id);
        console.log(result, "result-------------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAll();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const handleChange = (e) => {
        if (e.target.value === usersCount) {
            setSkip(0)
            setLimit(usersCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }

    function truncateStringToWords(str, numWords) {
        const words = str.split('');
        const truncatedWords = words.slice(0, numWords);
        return truncatedWords.join('');
    }


    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <NavPaper>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div></div>
                            <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Suggestions - {usersCount}</div></Grid>
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page - </div></Grid>
                                <Grid item>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard-label"
                                            value={limit}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="5">5</MenuItem>
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value={usersCount}>All</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <div className='searchBar'>
                                        <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                        <Search className='searchIcon' />
                                    </div>
                                </Grid>
                                {/* <Grid item>
                                    <button className='newBtn' onClick={handleUserOpen}>Create User</button>
                                </Grid> */}
                            </Grid>

                        </div>
                    </NavPaper>
                    <BackPaper>
                        {all.length === 0 && searchKey ?
                            <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                <Grid item><img src="/images/nodata.jpg" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                <Grid item><div className="errorMessage">User not found!</div></Grid>
                            </Grid> :
                            <>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No</TableCell>
                                            <TableCell align="center">Suggested By</TableCell>
                                            <TableCell align="center">Email</TableCell>
                                            <TableCell align="center">place</TableCell>
                                            <TableCell align="center">Country</TableCell>
                                            <TableCell align="center">Annual Emission CO2</TableCell>
                                            <TableCell align="center">Suggested At</TableCell>
                                            {/* <TableCell align="center">Subscription Status</TableCell> */}
                                            {/* <TableCell></TableCell> */}
                                            {/* <TableCell align="center">User Deletion</TableCell> */}
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {all.map((element, index) => {
                                            let date = new Date(element.createdAt);
                                            let monthsArray = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                                            let joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`
                                            return (<>
                                                <TableRow key={index + 1}>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="center">
                                                        {element.user.user_name}
                                                    </TableCell>
                                                    <TableCell align="center"> {element.user.email}</TableCell>
                                                    <TableCell align="center">{truncateStringToWords(element.place, 10)}...</TableCell>
                                                    <TableCell align="center">{element.country}</TableCell>
                                                    <TableCell align="center">{element.annual_emission_of_co2}</TableCell>
                                                    <TableCell align="center">{joinedSince}</TableCell>
                                                    <TableCell align="center">
                                                        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                            <Grid item>
                                                                <Tooltip title="view suggestion" >
                                                                    <NavLink to='/home/suggestions/detail' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Delete suggestion" >
                                                                    <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </>
                        }


                        {/* dilogue for delete suggestion */}
                        <Dialog open={openDialogForRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure want to remove this Suggestion from website?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleRemoveClose}>No</Button>
                                <Button onClick={() => { removeUser(id) }} >Yes</Button>
                            </DialogActions>
                        </Dialog>

                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Pagination count={Math.ceil(usersCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                            </div>
                        </div>
                    </BackPaper>

                </>
            )}
        </>

    )
}

export default Suggestions









