import React, { useState } from 'react';
import './sidebar.css';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink } from 'react-router-dom';
import { Home, QueryStats, Category, AccountCircle, TrendingUp, Message, Feedback, Email, Storefront, Leaderboard, CurrencyBitcoin, LocalGroceryStore, LocalMall, Loyalty, Flag, Policy, FindInPage, ReportProblem, Inventory, ProductionQuantityLimits, NoAccounts } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';


export default function Sidebar() {


    const [dashboardOpen, setDashboardOpen] = useState(true);
    const [userManagementOpen, setUserManagementOpen] = useState(false);
    const [eventsManagementOpen, setEventsManagementOpen] = useState(false);
    const [graphsManagementOpen, setGraphsManagementOpen] = useState(false);
    const [newsManagementOpen, setNewsManagementOpen] = useState(false);
    const [contentManagementOpen, setContentManagementOpen] = useState(false);
  
    const handleToggle = (stateSetter) => {
      stateSetter((prevState) => !prevState);
    };


    return (
        <>
        <div className='sidebar'>
      <div className='sidebarWrapper'>
        <div className='sidebarMenu' >
          <h3 className='sidebarTitle d-flex justify-content-between align-items-center'  onClick={() => handleToggle(setDashboardOpen)}>
            Dashboard
            {dashboardOpen ? < ExpandLessIcon/> : <ExpandMoreIcon />}
          </h3>
          
          {dashboardOpen && (
            <ul className='sidebarList'>
              <NavLink to='/home' className='link'>
                <li className='sidebarListItem'>
                  <Home className='sidebarIcon' />
                  Home
                </li>
              </NavLink>
            </ul>
          )}
        </div>
        <hr></hr>

        <div className='sidebarMenu'>
          <h3 className='sidebarTitle d-flex justify-content-between align-items-center' onClick={() => handleToggle(setUserManagementOpen)}>
            User Management
            {userManagementOpen ? < ExpandLessIcon/> : <ExpandMoreIcon />}
          </h3>
          {userManagementOpen && (
            <ul className="sidebarList">

                        <NavLink to='/users' className='link'>
                            <li className="sidebarListItem">
                                <AccountCircle className='sidebarIcon' />
                                Users
                            </li>
                        </NavLink>
                        <NavLink to='/home/removed-users' className='link'>
                            <li className="sidebarListItem">
                                <NoAccounts className='sidebarIcon' />
                                Deleted Users
                            </li>
                        </NavLink>
                        </ul>
          )}
        </div>
        <hr></hr>
        <div className='sidebarMenu'>
          <h3 className='sidebarTitle d-flex justify-content-between align-items-center' onClick={() => handleToggle(setEventsManagementOpen)}>
          Events Management {eventsManagementOpen ? < ExpandLessIcon/> : <ExpandMoreIcon />}
          </h3>
          {eventsManagementOpen && (
            <ul className="sidebarList">

                        <NavLink to='/home/events' className='link'>
                            <li className="sidebarListItem">
                                <AccountCircle className='sidebarIcon' />
                                Events
                            </li>
                        </NavLink>
                        <NavLink to='/home/setdistance' className='link'>
                            <li className="sidebarListItem">
                            <EventBusyIcon className='sidebarIcon' />
                                Set Distance
                            </li>
                        </NavLink>
                        <NavLink to='/home/suggestions' className='link'>
                            <li className="sidebarListItem">
                                <EventBusyIcon className='sidebarIcon' />
                                Suggestions
                            </li>
                        </NavLink>
                        </ul>
          )}
        </div>
        <hr></hr>
                <div className="sidebarMenu">
                <h3 className='sidebarTitle d-flex justify-content-between align-items-center' onClick={() => handleToggle(setGraphsManagementOpen)}>
          Graphs Management {graphsManagementOpen ? < ExpandLessIcon/> : <ExpandMoreIcon />}
          </h3>
          {graphsManagementOpen && (
                    <ul className="sidebarList">

                        <NavLink to='/home/CO2' className='link'>
                            <li className="sidebarListItem">
                                <EventIcon className='sidebarIcon' />
                                CO<sub>2</sub>  Graph
                            </li>
                        </NavLink>
                        <NavLink to='/home/temp' className='link'>
                            <li className="sidebarListItem">
                                <EventBusyIcon className='sidebarIcon' />
                                Global Temp Graph
                            </li>
                        </NavLink>
                    </ul>
                    )}
                </div>
         
                <hr></hr>
                <div className="sidebarMenu">
                    <h3 className='sidebarTitle d-flex justify-content-between align-items-center' onClick={() => handleToggle(setNewsManagementOpen)}>
          News Management {newsManagementOpen ? < ExpandLessIcon/> : <ExpandMoreIcon />}
          </h3>
          {newsManagementOpen && (
                    <ul className="sidebarList">

                        <NavLink to='/home/news' className='link'>
                            <li className="sidebarListItem">
                                <EventIcon className='sidebarIcon' />
                                News List
                            </li>
                        </NavLink>
                    </ul>
          )}
                </div>
                <hr></hr>
                {/* <div className="sidebarMenu">
                <h3 className="sidebarTitle">News Management</h3>
                <ul className="sidebarList">

                    <NavLink to='/products' className='link'>
                    <li className="sidebarListItem">
                    <ProductionQuantityLimits className='sidebarIcon'/>
                    none
                    </li>
                    </NavLink>
                    <NavLink to='/shops' className='link'>
                    <li className="sidebarListItem">
                    <LocalGroceryStore className='sidebarIcon'/>
                    none
                    </li>
                     </NavLink>
                    <NavLink to='/categories' className='link'>
                    <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                    none
                    </li>
                     </NavLink>
                    <NavLink to='/orders' className='link'>
                    <li className="sidebarListItem">
                    <LocalMall className='sidebarIcon'/>
                    none
                    </li>
                     </NavLink>
                    <NavLink to='/subscriptions' className='link'>
                    <li className="sidebarListItem">
                    <Loyalty className='sidebarIcon'/>
                    none
                    </li>
                     </NavLink>
                    <NavLink to='/disputes' className='link'>
                    <li className="sidebarListItem">
                    <Flag className='sidebarIcon'/>
                    none
                    </li>
                     </NavLink>
                     
                </ul>
            </div> */}
                <div className="sidebarMenu">
                <h3 className='sidebarTitle d-flex justify-content-between align-items-center' onClick={() => handleToggle(setContentManagementOpen)}>
          Content Management {contentManagementOpen ? < ExpandLessIcon/> : <ExpandMoreIcon />}
          </h3>
          {contentManagementOpen && (
                    <ul className="sidebarList">
                        <NavLink to='/privacy_Policy' className='link'>
                            <li className="sidebarListItem">
                                <Policy className='sidebarIcon' />
                                Privacy Policy
                            </li>
                        </NavLink>
                        <NavLink to='/terms_of_use' className='link'>
                            <li className="sidebarListItem">
                                <FindInPage className='sidebarIcon' />
                                Terms Of Use
                            </li>
                        </NavLink>
                        <NavLink to='/legal_mention' className='link'>
                            <li className="sidebarListItem">
                                <ReportProblem className='sidebarIcon' />
                                Legal Mention
                            </li>
                        </NavLink>
                        <NavLink to='/substantiated' className='link'>
                            <li className="sidebarListItem">
                                <ReportProblem className='sidebarIcon' />
                                Substantiated
                            </li>
                        </NavLink>
                        <NavLink to='/home/faq' className='link'>
                            <li className="sidebarListItem">
                                <ReportProblem className='sidebarIcon' />
                                Faq
                            </li>
                        </NavLink>
                        <NavLink to='/home/feedbacks' className='link'>
                            <li className="sidebarListItem">
                                <ReportProblem className='sidebarIcon' />
                                Feedbacks
                            </li>
                        </NavLink>
                    </ul>
          )}
                </div>

            </div>
        </div>
</>
    )

}
