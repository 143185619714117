import { AddToPhotos, FreeBreakfastSharp, WorkspacePremium, Search, CheckCircle, Cancel, RadioButtonUnchecked, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { Chip, Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import apiFunctions from '../../api/apiFunctions';
import Toptag from '../../components/toptag/Toptag'
import "./CO2.css"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Loader from '../../components/Loader/Loader'
import { URL } from '../../api/apiUrls'


const BackPaper = styled(Paper)({
    height: "600px",
    borderRadius: "0",
    padding: "20px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const CO2 = ({ objs }) => {

    const location = useLocation();
    // console.log(location, "location---------------------")
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [edit, setEdit] = useState(false)
    const [image, setImage] = useState("")
    const [image2, setImage2] = useState("")
    const [error, setError] = useState(false)
    const [obj, setObj] = useState({})

    // const [updatedObject, setUpdatedObject] = useState({
    //     user_name: '',
    //     email: '',
    //     skills: '',
    // })
    // console.log(updatedObject, "name at a time when gettin userprofilew----------------")

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // const openDialog = async () => {
    //     setIsDialogOpen(true);
    // };

    const imageChange = (e) => {

        setImage2(e.target.files[0])
        const file = e.target.files[0];
        console.log(file, "file**********************")
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
        } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
            setImage2("")
            setError(true)
        } else {
            setError(true)
        }

    }
    // console.log(image2, "imatge2222222222222222222222222222222")
    // console.log(image, "imageggggggggggggggggggggggg")


    const handlecChange = () => {
        setEdit(true)
    }

    const addata = (e) => {
        console.log(e.target.value, "value----------+++++++++++++++")
        const { name, value } = e.target;
        setObj((obj)=>({
            ...obj,
            [name]: value,
        }));
    }

    // console.log(updatedObject, "updated+++++++++++++++++++++++++++++++++++-----------------------------")


    const getGraphDetail = async (previous) => {
        // console.log("hellogetUserDeatil")
        const result = await apiFunctions.getGraphDetails("co2");
        console.log(result, "result*---------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setObj(result.data.data)
            setImage(result.data.data.graph_photo)
            await delay(1000);
            setIsLoading(false)
        }
    }
    // console.log(obj, "objetttttttttttttttttttttttttttttttttttttttttttt+++++++++++")

    const updateGraphDetails = async (event) => {
        const id = obj._id;
        event.preventDefault();
        localStorage.removeItem('image')
        const formData = new FormData()
        formData.append("image", image2)
        let body;
        console.log(formData, "formData9999999999999")
        const profilePhotoPath = await apiFunctions.uploadGraphPhoto(formData)
        console.log(profilePhotoPath, "profilePhotoPath-------")
        if (!profilePhotoPath.data.status) {
            body = {
                value: obj.value,
                warning : obj.warning,
                range_from: obj.range_from,
                range_to: obj.range_to,
                graph_photo: image
            }
        } else {
            body = {
                value: obj.value,
                range_from: obj.range_from,
                warning : obj.warning,
                range_to: obj.range_to,
                graph_photo: profilePhotoPath.data.data.graph_photo
            }
        }
        // console.log(body, "hdhsfhdghfgdshgfgdsgfhdghgfdghgfhdg")
        const result = await apiFunctions.updateGraphDetails(id, body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        } else {
            setIsLoading(false)
            setEdit(false)
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
    }


    useEffect(() => {
        getGraphDetail()
    }, [])

    // console.log(selectedUsers, "user is here")
    return (
        <>
        <ToastContainer autoClose={1000} />
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>CO<sub>2</sub> GRAPH</Grid>
                            <Grid item>
                                {!edit ? (
                                    <>
                                        <button className='newBtn' onClick={handlecChange}>Update</button>
                                        {/* <button className='newBtn' onClick={openDialog}>Add friends</button> */}
                                    </>) :
                                    null
                                }
                            </Grid>
                        </Grid>
                        <hr></hr>

                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} lg={5} item >
                                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        {image !== '' ? (
                                            <>
                                                <img src={image} alt="user" className='imgCont1' />
                                            </>
                                        ) : (
                                            <img src="/images/blank_pic.png" className='imgCont1' alt="" />

                                        )}
                                        <Grid item>
                                            {edit ? <div className="image-upload uploadicon">
                                                <label htmlFor="image">
                                                    <Tooltip title="Change user profile pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                                                </label>

                                                <input id="image" onChange={imageChange} type="file" name='image' accept="image/*" />
                                            </div> : null}
                                        </Grid>
                                        {error ? <><Grid item className='fileErr'>Invalid file format. Only JPG, PNG, and other image formats are accepted</Grid>
                                            <Grid item><button className='newBtn' onClick={() => setError(false)}>Ok</button></Grid></> : null}
                                    </Grid>
                                    <Grid item>
                                        {edit ? <button className='newBtn' onClick={updateGraphDetails}>Save</button> : null}
                                    </Grid>
                                </Grid>
                            </Grid><Grid xs={12} sm={12} md={6} lg={7} item>
                                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item>Live CO<sub>2</sub></Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={`${obj.value}`}
                                            name="value" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Normal Value From</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={obj.range_from}
                                            name="range_from" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Normal Value To</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={obj.range_to} name="range_to" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>Warning</Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <input type="text" disabled={!edit ? true : false} value={obj.warning}
                                            name="warning" onChange={addata} className="detailBarInput" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                      

                    </BackPaper>
                </>}

        </>
    )
}

export default CO2