import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/toptag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import apiFunctions from "../../api/apiFunctions";



const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Disclaimer = () => {

    const [state, setState] = useState('');
    const [id, setId] = useState('')
    const navigate = useNavigate();

    const handleChange = data => {
        setState(data)
    };

    const updateContent = async () => {
        let data = {
            _id: id,
            content: state
        }

        try {
            let result = await apiFunctions.updateTerms(data)
            if (!result.data.status) {
                if (result.data.code == 401) {
                    localStorage.clear('token');
                    sessionStorage.clear('token');
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });

            } else {
                toast.success("Successfully updated!")
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const getDisclaimerPage = async () => {
        try {
            let result = await apiFunctions.getLegal()
            if (!result.data.status) {
                if (result.data.code == 401) {
                    localStorage.clear('token');
                    sessionStorage.clear('token');
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });

            } else {
                setState(result.data.data.content)
                setId(result.data.data._id)
            }
        } catch (error) {
            console.log(error.message)
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    useEffect(() => {
        getDisclaimerPage()
    }, [])


    return (
        <>
            <div className='container-fluid'>
                <Toptag />
                <ToastContainer autoClose={1000} />
                <BackPaper>
                    <EditorToolbar />
                    <ReactQuill
                        style={{ height: '470px', overflow: "auto" }}
                        theme="snow"
                        id='quillState'
                        value={state}
                        onChange={handleChange}
                        placeholder={"Write something..."}
                        modules={modules}
                        formats={formats}
                    />
                    <Grid container style={{ marginTop: "10px" }} display="flex" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <button className="newBtn" onClick={updateContent}>SUBMIT</button>
                        </Grid>
                    </Grid>
                </BackPaper>
            </div>
        </>
    )
}

export default Disclaimer;