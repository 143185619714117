import React, { useRef, useState, useEffect } from 'react';
import './profile.css';
import Toptag from '../../components/toptag/Toptag';
import { AddToPhotos, ConnectingAirportsOutlined, Publish, TwoKPlus } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Grid, Paper } from '@mui/material';
import apiFunctions from '../../api/apiFunctions';
import { URL } from '../../api/apiUrls';


const NavPaper = styled(Paper)({
    padding: "20px",
    borderRadius: "0",
    backgroundColor: "white",
    marginBottom: "5px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    padding: "10px",
    height: "440px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
export default function Profile() {

    const location = useLocation();
    const navigate = useNavigate();
    const [dis, setDis] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState({ user_name: '', email: '' , id : ''})
    const [image, setImage] = useState("")
    const [error, setError] = useState(false)
    const [image2, setImage2] = useState("")
    const [data, setData] = useState([])

    const editFunction = () => {
        setDis(false)
    }

    const inputEvent = (e) => {
        setDetail({
            ...detail,
            [e.target.name]: e.target.value,
        })
    }

    const imageChange = (e) => {
        setImage2(e.target.files[0])
        const file = e.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/avif') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
        } else if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
            setImage2("")
            setError(true)
        } else {
            setError(true)
        }

    }


    const getProfile = async () => {
        try {
            const result = await apiFunctions.getAdminProfile()
            console.log(result, "result----------------------3333333")
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('token');
                    navigate("/login")
                    toast.info("Session Expired Please login again!!")
                }

                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                setIsLoading(false)
                setImage(result.data.data.profilePhoto)
                setDetail({
                    id : result.data.data.id, 
                    user_name: result.data.data.user_name,
                    email: result.data.data.email
                })
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    console.log(detail, "detail66666666666")

    const updateAdmin = async (event) => {
        event.preventDefault();
        localStorage.removeItem('image')
        const formData = new FormData()
        formData.append("image", image2)
        let userObj;
        const profilePhotoPath = await apiFunctions.uploadPhoto(formData)
        // console.log(profilePhotoPath, "profilePhotoPath-------")
        if (!profilePhotoPath.data.status) {
            userObj = {
                id: detail.id,
                user_name: detail.user_name,
                email: detail.email,
                profilePhoto : localStorage.getItem('profilePhoto')
            }
        } else {
            userObj = {
                id: detail.id,
                user_name: detail.user_name,
                email: detail.email,
                profilePhoto: profilePhotoPath.data.data.profile_photo
            }
        }

        console.log(detail, "detauilssjafjkhdfkjhdkjashfkjh")
        const result = await apiFunctions.updateAdminProfile(userObj);
        // console.log(result, "result update adminprofile----------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.data, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setIsLoading(false)
            toast.success("Updated successfully!")
            localStorage.setItem('profilePhoto', result.data.data.profilePhoto)
            getProfile();
            setDis(true)
            // setTimeout(window.location.reload.bind(window.location), 10);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        getProfile()
    }, [])

    return (
        <>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <div className='container-fluid'>
                        <Toptag />
                        <ToastContainer autoClose={1000} />
                        <NavPaper>
                            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Admin Profile&nbsp;&nbsp;<i className="fa-solid fa-key"></i></Grid>
                                <Grid item>
                                    {dis === true ? <button className='newBtn' onClick={editFunction}>Edit</button> : null}
                                </Grid>
                            </Grid>
                        </NavPaper>
                        <BackPaper>
                            <Grid container spacing={4}>
                                <Grid xs={12} sm={12} md={6} lg={3} item>
                                    <Grid container spacing={2} direction="column" justifyContent="space-evenly" alignItems="center">
                                        <Grid item>


                                            {!image ?
                                                <img src={image} alt="/images/blank_pic.png" className='imgCont1' />
                                                : (<img src={image} alt="/images/blank_pic.png" className='imgCont1' />
                                                )
                                            }
                                        </Grid>
                                        <Grid item>
                                            {!dis ? <div className="image-upload">
                                                <label htmlFor="image">
                                                    <Tooltip title="Change your profile pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                                                </label>

                                                <input id="image" onChange={imageChange} type="file" name='image' accept="image/*" />
                                            </div> : null}
                                        </Grid>
                                        {error ? <><Grid item className='fileErr'>Invalid file format. Only JPG, PNG, and other image formats are accepted</Grid>
                                            <Grid item><button className='newBtn' onClick={() => setError(false)}>Ok</button></Grid></> : null}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={9} item>
                                    <Grid container spacing={1} direction="column" justifyContent="space-evenly" alignItems="flex-start">
                                        <Grid item><label className='myLabel'>Name</label></Grid>
                                        <Grid item style={{ width: "90%" }}><input type="text" name="user_name" disabled={dis ? true : false} value={detail.user_name} id="user_name" onChange={inputEvent} className="BarInput" /></Grid>
                                        <Grid item><label className='myLabel'>Email</label></Grid>
                                        <Grid item style={{ width: "90%" }}><input type="text" name="email" disabled={dis ? true : false} value={detail.email} id="email" onChange={inputEvent} className="BarInput" /></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                    {dis === false && error === false ? <div className="SubmitBtnBox">
                                        <Tooltip title="Click to Update profile" >
                                            <button className='newBtn' onClick={updateAdmin} >
                                                Update
                                            </button>
                                        </Tooltip>
                                    </div> : null
                                    }
                                </Grid>
                            </Grid>

                        </BackPaper>
                    </div>

            }
        </>

    )
}
