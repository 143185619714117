import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Tab, Tabs, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
import "./users.css"
import apiFunctions from '../../api/apiFunctions'
import { elements } from 'chart.js'
import { URL } from '../../api/apiUrls'


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const FriendOrGroup = () => {
    const location = useLocation();
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [friendsCount, setFriendsCount] = useState(0)
    const [groupsCount, setGroupsCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState('5');
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForBlock, setOpenDialogForBlock] = React.useState(false);
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [type, setType] = useState("1")
    const [changeColour, setChangeColour] = useState("yellow")
    const [id, setId] = useState()
    const [groups, setGroups] = useState([]);
    const [blockedStatus, setBlockedStatus] = useState()
    const [err, setErr] = useState(false)




    const handleBlockOpen = (id, status) => {
        setId(id)
        setBlockedStatus(status)
        setOpenDialogForBlock(true);
    };

    const handleBlockClose = () => {
        setOpenDialogForBlock(false);
    };

    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }


    const handleChangeType = (event, newValue) => {
        setType(newValue);
        setChangeColour("black");
        setSkip(0);
        setPage("1");
        setLimit("5");
        setSearchKey("");
        if (newValue === "1") {
            getAllFriends();
        } else if (newValue === "2") {
            getAllGroups();
        }
    }

    const getAllFriends = async () => {
        let body = {
            _id: location.state.id,
        }

        let result = await apiFunctions.getAllFriendsAndGroups(body, limit, searchKey, page);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {

            setAll(result.data.data);
            setFriendsCount(result.data.total_count)
            setIsloading(false)
        }
    }

    const getAllGroups = async () => {
        let body = {
            _id: location.state.id,
        }

        let groups = await apiFunctions.Groups(body, limit, searchKey, page);
        if (!groups.data.status) {
            if (groups.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(groups.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setGroups(groups.data.data.data);
            setGroupsCount(groups.data.data.total);
            setIsloading(false);
        }
    }

    useEffect(() => {
        getAllFriends()
        getAllGroups()
    }, [searchKey, limit, page])

    const unFriend = async (id) => {
        const body = {
            userId: location.state.id,
            friendId: id
        }

        const result = await apiFunctions.unFriend(body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAllFriends();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const BlockUser = async (id) => {
        const body = {
            _id: id
        }

        const result = await apiFunctions.blockUser(body);
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear('admintoken');
                sessionStorage.clear('admintoken');
                navigate("/")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForBlock(false);
            getAllFriends();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const handleChange = (e) => {
        if (e.target.value === friendsCount) {
            setSkip(0)
            setLimit(friendsCount)
        } else if (e.target.value === groupsCount) {
            setSkip(0)
            setLimit(groupsCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }


    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <Grid container   spacing={3}  direction="row"  justifyContent="center"  alignItems="center">
                        <Grid item>
                        <Tabs
                                value={type}
                                onChange={handleChangeType}
                                aria-label="wrapped label tabs example"
                                
                            >
                                <Tab
                                    style={{  background: { changeColour } }}
                                    value="1"
                                    label="FRIENDS"
                                />

                                <Tab
                                    style={{  background: { changeColour } }}
                                    value="2"
                                    label="GROUPS"
                                />
                            </Tabs>
                        </Grid>

                    </Grid>
                    
                    <NavPaper>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div></div>
                            <Grid
                                container
                                spacing={4}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item>
                                    <div
                                        style={{
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            color: "grey",
                                        }}
                                    >
                                        Total - {type == 1 ? friendsCount : groupsCount}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div
                                        style={{
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            color: "grey",
                                        }}
                                    >
                                        Row Per Page -{" "}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1, minWidth: 50 }}
                                    >
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard-label"
                                            value={limit}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="5">5</MenuItem>
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value={type == 1 ? friendsCount : groupsCount}>All</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item>
                                    <div className="searchBar">
                                        <input
                                            type="text"
                                            placeholder="Search... "
                                            onChange={searchHandle}
                                            id="searchtext"
                                            className="searchBarInput"
                                        />
                                        <Search className="searchIcon" />
                                    </div> */}
                                {/* </Grid> */}
                            </Grid>
                        </div>
                    </NavPaper>
                    <BackPaper>
                        {type === "1" ? (
                            all.length === 0 && searchKey ? (
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginTop: "30px" }}
                                    direction="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <img
                                            src="/images/no-data.png"
                                            alt="error-image"
                                            style={{ height: "150px", width: "200px" }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className="errorMessage">Friends not found!</div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>S.No</TableCell>
                                                <TableCell>User</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone</TableCell>
                                                <TableCell>Joined Since</TableCell>
                                                <TableCell>Active Status</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {all.map((element, index) => {
                                                let date = new Date(element.createdAt);
                                                let monthsArray = [
                                                    "Jan",
                                                    "Feb",
                                                    "March",
                                                    "Apr",
                                                    "May",
                                                    "Jun",
                                                    "July",
                                                    "Aug",
                                                    "Sept",
                                                    "Oct",
                                                    "Nov",
                                                    "Dec",
                                                ];
                                                let joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]
                                                    } ${date.getFullYear()}`;
                                                return (
                                                    <>
                                                        <TableRow key={index + 1}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                {element.profilePhoto !== "" ? (
                                                                    <img
                                                                        src={`${element.profilePhoto}`}
                                                                        alt="user"
                                                                        style={{
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            borderRadius: "50px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="/images/blank_pic.png"
                                                                        style={{
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            borderRadius: "50px",
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {element.fullName}
                                                            </TableCell>
                                                            <TableCell>{element.mobileNumber}</TableCell>
                                                            <TableCell>{joinedSince}</TableCell>
                                                            {/* <TableCell align="center">
                                                                {element.premiumStatus === false ? (
                                                                    <Chip
                                                                        label="free"
                                                                        className="statusChip"
                                                                        icon={
                                                                            <FreeBreakfastSharp className="FreeBreakfastSharp" />
                                                                        }
                                                                    />
                                                                ) : (
                                                                    "..."
                                                                )}
                                                            </TableCell> */}
                                                            <TableCell>
                                                                {element.isBlocked === false ? (
                                                                    <Chip
                                                                        label="Active"
                                                                        className="statusChipActive"
                                                                    />
                                                                ) : (
                                                                    <Chip
                                                                        label="Blocked"
                                                                        className="statusChipBlock"
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    <Grid item>
                                                                        <Tooltip title="view profile">
                                                                            <NavLink
                                                                                to="/users/profile"
                                                                                state={{ id: element._id }}
                                                                            >
                                                                                <RemoveRedEye className="visibilityIc" />
                                                                            </NavLink>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Tooltip
                                                                            title={
                                                                                !element.isBlocked
                                                                                    ? "block user"
                                                                                    : "unblock user"
                                                                            }
                                                                        >
                                                                            {element.isBlocked === true ? (
                                                                                <Block
                                                                                    className="blockIcon"
                                                                                    style={{ color: "green" }}
                                                                                    onClick={() => {
                                                                                        handleBlockOpen(
                                                                                            element._id,
                                                                                            element.isBlocked
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Block
                                                                                    className="blockIcon"
                                                                                    style={{ color: "red" }}
                                                                                    onClick={() => {
                                                                                        handleBlockOpen(
                                                                                            element._id,
                                                                                            element.isBlocked
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {!all.includes(element._id) ? (
                                                                            <Tooltip title="UnFriend ">
                                                                                <PersonRemoveAlt1Icon
                                                                                    className="userListDelete"
                                                                                    onClick={() => {
                                                                                        handleRemoveOpen(element._id);
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : null}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <div>
                                            <Pagination
                                                count={Math.ceil(friendsCount / limit)}
                                                page={Math.floor(skip / limit) + 1}
                                                variant="outlined"
                                                onChange={skipPagination}
                                                shape="rounded"
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : type === "2" ? (
                            groups.length === 0 && searchKey ? (
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginTop: "30px" }}
                                    direction="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <img
                                            src="/images/no-data.png"
                                            alt="error-image"
                                            style={{ height: "150px", width: "200px" }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className="errorMessage">Group not found!</div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <div>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sr. No</TableCell>
                                                <TableCell>Groups</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {groups.map((group, index) => {
                                                let groupDate = new Date(group.createdAt);
                                                let monthsArray = [
                                                    "Jan",
                                                    "Feb",
                                                    "March",
                                                    "Apr",
                                                    "May",
                                                    "Jun",
                                                    "July",
                                                    "Aug",
                                                    "Sept",
                                                    "Oct",
                                                    "Nov",
                                                    "Dec",
                                                ];
                                                let groupJoinedSince = `${groupDate.getDate()} ${monthsArray[groupDate.getMonth()]
                                                    } ${groupDate.getFullYear()}`;
                                                return (
                                                    <>
                                                        <TableRow key={group.id}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{group.groupPhoto !== "" ? (
                                                                <img
                                                                    src={`${group.groupPhoto}`}
                                                                    alt="Group"
                                                                    style={{
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        borderRadius: "50px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/images/blank_pic.png"
                                                                    style={{
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        borderRadius: "50px",
                                                                    }}
                                                                    alt=""
                                                                />
                                                            )}</TableCell>
                                                            <TableCell>{group.name}</TableCell>
                                                            <TableCell>{groupJoinedSince}</TableCell>
                                                            <TableCell align="right">
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    <Grid item>
                                                                        <Tooltip title="Group Detail">
                                                                            <NavLink
                                                                                to="/groupdetail"
                                                                                state={{ id: group._id }}
                                                                            >
                                                                                <RemoveRedEye className="visibilityIc" />
                                                                            </NavLink>
                                                                        </Tooltip>
                                                                    </Grid>

                                                                    {/* <Grid item>
                                                                        {!groups.includes(group._id) ? (
                                                                            <Tooltip title="Delete Group">
                                                                                <PersonRemoveAlt1Icon
                                                                                    className="userListDelete"
                                                                                    onClick={() => {
                                                                                        handleRemoveOpen(group._id);
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : null}
                                                                    </Grid> */}
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <div>
                                            <Pagination
                                                count={Math.ceil(groupsCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded"
                                            />
                                        </div>
                                    </div>

                                </div>


                            )
                        ) : null}

                        {/* dilogue for block user */}
                        <Dialog
                            open={openDialogForBlock}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {blockedStatus === true
                                    ? "Are you sure want to unblock this user?"
                                    : "Are you sure want to block this user?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleBlockClose}>Cancel</Button>
                                <Button onClick={() => { BlockUser(id) }}>
                                    {blockedStatus === true ? "unBlock" : "Block"}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* dilogue for delete user */}
                        <Dialog
                            open={openDialogForRemove}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure want to unfriend this user ?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleRemoveClose}>No</Button>
                                <Button onClick={() => { unFriend(id) }}>Yes</Button>
                            </DialogActions>
                        </Dialog>

                    </BackPaper>
                </>
            )}
        </>
    )
}

export default FriendOrGroup









