import React from 'react';
import Card from '../../components/cards/Card';



function Home() {
 

  return (
    <>
    {/* {loggedIn ?
      <Card /> : <Login/> 
    } */}
    <Card/>
    </>
  )
}

export default Home