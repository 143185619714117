import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, UploadFile, WorkspacePremium } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, MenuItem, Pagination, Paper, Select, Tooltip, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
// import "./users.css"
import apiFunctions from '../../api/apiFunctions'


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const NewsList = () => {
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [newsCount, setNewsCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(6);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [id, setId] = useState()
  

    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }

    const getAll = async () => {
        let result = await apiFunctions.getAllNews(limit, searchKey, page);
        console.log(result, "result-------------------999999999")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data.data);
            setNewsCount(result.data.data.total_count)
            setIsloading(false)
        }
    }


    useEffect(() => {
        getAll()
    }, [searchKey, limit, page])

    const deleteNews = async (id) => {

        const result = await apiFunctions.removeNews(id);
        console.log(result, "result-------------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAll();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }



    const handleChange = (e) => {
        if (e.target.value === newsCount) {
            setSkip(0)
            setLimit(newsCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }

    function truncateStringToWords(str, numWords) {
        const words = str.split('');
        const truncatedWords = words.slice(0, numWords);
        return truncatedWords.join('');
    }


    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <NavPaper>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div></div>
                            <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total News - {newsCount}</div></Grid>
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>News Per Page - </div></Grid>
                                <Grid item>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard-label"
                                            value={limit}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="6">6</MenuItem>
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value={newsCount}>All</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <div className='searchBar'>
                                        <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                        <Search className='searchIcon' />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <NavLink to="/home/news/addnews" >
                                        <button className='newBtn' >ADD NEWS</button>
                                    </NavLink>
                                </Grid>
                            </Grid>

                        </div>
                    </NavPaper>
                    <BackPaper>
                        {all.length === 0 && searchKey ?
                            <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                <Grid item><img src="/images/nodata.jpg" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                <Grid item><div className="errorMessage">News not found!</div></Grid>
                            </Grid> :
                            <>
                                <div style={{ gap: "20px", marginLeft: "40px", width: "925px", marginRight : '20px'}} className='mt-3 d-flex justify-content-start align-items-start flex-wrap flex-row'>
                                    {all.map((element, index) => {
                                        let date = new Date(element.createdAt);
                                        let monthsArray = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                                        let joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`
                                        return (<>
                                            <div className="card" style={{ width: "18rem" }}>
                                                {element.news_photo !== '' ? (
                                                    <img src={`${element.news_photo}`} alt="user" className="card-img-top " style={{ height: "100px" }} />
                                                ) : (
                                                    <img src="/images/blank_pic.png" className="card-img-top" style={{ height: "150px" }} alt="" />
                                                )}
                                                <div className="card-body" style={{ fontFamily: "math", fontSize: "smaller", padding: "10px 10px 10px 10px", height:"150px"}}>
                                                    <h5 className="card-title">{truncateStringToWords(element.title, 22)}...</h5>
                                                    <p className="card-text">{truncateStringToWords(element.description, 80)}...</p>

                                                    <div className="card-buttons" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '10px', width: '95%' }}>
                                                        <div>
                                                            <NavLink to="/home/news/detail" state={{ id: element._id }}>
                                                                <button className="btn btn-info">Info</button>
                                                            </NavLink>
                                                        </div>
                                                        <div>
                                                            <IconButton  size="large" onClick={() => { handleRemoveOpen(element._id) }} style={{ marginRight: "50px", padding: "0px 12px 8px 12px" }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <p className="card-text" style={{ fontSize: "larger", marginBottom: "10px" }}>{joinedSince}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>)
                                    })}
                                </div>
                            </>
                        }


                        {/* dilogue for delete user */}
                        <Dialog open={openDialogForRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure want to remove this user from website?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleRemoveClose}>No</Button>
                                <Button onClick={() => { deleteNews(id) }} >Yes</Button>
                            </DialogActions>
                        </Dialog>

                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Pagination count={Math.ceil(newsCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                            </div>
                        </div>
                    </BackPaper>

                </>
            )}
        </>

    )
}

export default NewsList









