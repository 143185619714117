import {RemoveCircleOutline, RemoveRedEye, Search} from '@mui/icons-material'
import { Button,Tab, Tabs, Dialog, DialogActions, DialogTitle, FormControl, Grid, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Toptag from '../../components/toptag/Toptag'
import "./events.css"
import apiFunctions from '../../api/apiFunctions'


const NavPaper = styled(Paper)({
    padding: "10px",
    borderRadius: "0",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
    height: "580px",
    overflow: "auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})


const Events = () => {
    const [all, setAll] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [eventCount, setEventCount] = useState(0)
    const navigate = useNavigate();
    const [limit, setLimit] = useState(5);
    const [skip, setSkip] = useState('0');
    const [page, setPage] = useState("1")
    const [searchKey, setSearchKey] = useState("");
    const [openDialogForRemove, setOpenDialogForRemove] = React.useState(false);
    const [id, setId] = useState()
    const [type, setType] = useState("active")
    const [changeColour, setChangeColour] = useState("yellow")


    const handleRemoveOpen = (id) => {
        setId(id)
        setOpenDialogForRemove(true);
    };

    const handleRemoveClose = () => {
        setOpenDialogForRemove(false);
    };

    const searchHandle = (event) => {
        const newQuery = event.target.value;
        setSearchKey(newQuery)
        setSkip(0);
    }



    const getAll = async () => {
        let result = await apiFunctions.getAllEvents(limit, searchKey, page, type);
        console.log(result, "result888888888888888888888888")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAll(result.data.data);
            setEventCount(result.data.total_count)
            setIsloading(false)
        }
    }

    useEffect(() => {
        getAll()
    }, [searchKey, limit, page, type])


    const handleChangeType = (event, newValue) => {
        setType(newValue);
        setChangeColour("black");
    //    getAll()
    }

    const removeEvent = async (id) => {
        const body = {
            _id: id
        }
        const result = await apiFunctions.removeEvent(body);
        console.log(result, "result-------------------------")
        if (!result.data.status) {
            if (result.data.code === 401) {
                localStorage.clear();
                navigate("/login")
                toast.info("Session Expired Please login again!!")
            }
            toast.error(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setOpenDialogForRemove(false);
            getAll();
            toast.success(result.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const handleChange = (e) => {
        if (e.target.value === eventCount) {
            setSkip(0)
            setLimit(eventCount)
        } else {
            setLimit(e.target.value)
        }
    };

    const skipPagination = (e, page) => {
        setSkip((page - 1) * limit)
        setPage(page)
    }

    function truncateStringToWords(str, numWords) {
        const words = str.split('');
        const truncatedWords = words.slice(0, numWords);
        return truncatedWords.join('');
    }


    return (
        <>
            {isLoading ? (
                <Loader />) : (
                <>
                    <Toptag />
                    <ToastContainer autoClose={1000} />
                    <Grid container   spacing={3}  direction="row"  justifyContent="center"  alignItems="center">
                        <Grid item>
                        <Tabs
                                value={type}
                                onChange={handleChangeType}
                                aria-label="wrapped label tabs example"
                                
                            >
                                <Tab
                                    style={{  background: { changeColour } }}
                                    value="past"
                                    label="PAST"
                                />

                                <Tab
                                    style={{  background: { changeColour } }}
                                    value="active"
                                    label="ACTIVE"
                                />
                            </Tabs>
                        </Grid>

                    </Grid>
                    <NavPaper>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div></div>
                            <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Events - {eventCount}</div></Grid>
                                <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page - </div></Grid>
                                <Grid item>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard-label"
                                            value={limit}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="5">5</MenuItem>
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value={eventCount}>All</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <div className='searchBar'>
                                        <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                                        <Search className='searchIcon' />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <NavLink to='/events/addevent'><button className='newBtn' >Add New Event</button></NavLink>
                                </Grid>
                            </Grid>

                        
                        </div>
                    </NavPaper>
                    <BackPaper>
                        {all.length === 0 && searchKey ?
                            <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                <Grid item><img src="/images/nodata.jpg" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                <Grid item><div className="errorMessage">User not found!</div></Grid>
                            </Grid> :
                            <>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell >Title</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell >place</TableCell>
                                            <TableCell>Country</TableCell>
                                            {/* <TableCell align="center">Subscription Status</TableCell> */}
                                            {/* <TableCell>Timing</TableCell> */}
                                            {/* <TableCell align="center">User Deletion</TableCell> */}
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {all.map((element, index) => {
                                            const firstDate = element.eventDate && element.eventDate.length > 0 ? new Date(element.eventDate[0].date) : null;
                                            const lastDate = element.eventDate && element.eventDate.length > 1 ? new Date(element.eventDate[element.eventDate.length - 1].date) : null;
                                            {/* let joinedSince;                                           
                                            {element.dateTimes.map((slot, index)=>{
                                            let date = new Date(slot.date);
                                            let monthsArray = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec",];
                                            joinedSince = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`;})} */}
                                            return (<>
                                                <TableRow key={index + 1}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{truncateStringToWords(element.title, 10)}...</TableCell>
                                                    <TableCell> {firstDate ?
                                                        element.eventDate.length <= 1
                                                            ? firstDate.toLocaleDateString()
                                                            : firstDate.toLocaleDateString() + " - " + lastDate.toLocaleDateString()
                                                        : "N/A"
                                                    }</TableCell>
                                                    <TableCell>{truncateStringToWords(element.place, 30)}...</TableCell>
                                                    <TableCell>{element.country}</TableCell>
                                                    {/* {element.dateTimes.map((slot)=>{
                                                    <TableCell>{slot.slots.startTime1} - {slot.slots.endTime1}     {slot.slots.startTime2} - {slot.slots.endTime2}
                                                    </TableCell>
                                                    })} */}
                                                    <TableCell align="right">
                                                        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                                            <Grid item>
                                                                <Tooltip title="Event Detail" >
                                                                    <NavLink to='/events/detail' state={{ id: element._id, lat: element.location.coordinates[1], lng: element.location.coordinates[0] }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                {element.isDeleted === false ?
                                                                    <Tooltip title="Delete Event" >
                                                                        <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                                                    </Tooltip>
                                                                    : null}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            )

                                        })}
                                    </TableBody>
                                </Table>

                            </>
                        }


                        {/* dilogue for delete user */}
                        <Dialog open={openDialogForRemove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure want to remove this event from website?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleRemoveClose}>No</Button>
                                <Button onClick={() => { removeEvent(id) }} >Yes</Button>
                            </DialogActions>
                        </Dialog>


                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Pagination count={Math.ceil(eventCount / limit)} page={Math.floor(skip / limit) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                            </div>
                        </div>
                    </BackPaper>

                </>
            )}
        </>

    )
}

export default Events









